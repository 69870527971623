import { MDBBtn, MDBCheckbox, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBRow, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';

import './Login.css'
import { login } from '../../functions/APICalls';
import { useNavigate } from 'react-router-dom';

function Login({
  mandantSettings,
  language,
  applicationID,
  APIUrl,
  setUser,
  setToken
}) {

  const navigate = useNavigate();

  const [rememberUser, setRememberUser] = useState(false);

  const [passwordType, setPasswordType] = useState('password')

  // store the username and password in the variables
  const [values, setValues] = useState({
    userName: "",
    password:""
  })

  //adminUserServerOutdoor
  //pass34worxd

  //feedback true or false
  const [feedbackBool, setFeedbackBool] = useState({
    userName: false,
    password:false
  })

  //feedback values
  const [feedback, setFeedback] = useState({
    userName: "",
    password:""
  });

  //value for showing password
  const [eye, setEye] = useState(false);

  function changePasswordType(currentType){
    if(currentType==="password"){
      setPasswordType("text");
      setEye(!eye)
    }else{
      setEye(!eye)
      setPasswordType("password")
      
    }
  }

  const onChange = (e) => {

    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function loginToPage(){
    // console.log(values.userName)
    // console.log( values.password)
    login(
      APIUrl, 
      applicationID, 
      values.userName, 
      values.password)
    .then(response=>{
      console.log(response)
      setUser(response);
      setToken(response.currentToken)
      navigate("/")
    })
    .catch(error=>{
      setValues({
        userName:"",
        password:""
      })
      setFeedback({
        userName:"invalid",
        password:"invalid"
      })
    });
  }

  // function for handling the submit
  function handleSubmit(e){
    if(rememberUser){
      window.sessionStorage.setItem('username', values.userName);
      window.sessionStorage.setItem('password', values.password)
    }
    loginToPage(
      APIUrl, 
      applicationID)
  }

  useEffect(()=>{
    if(window.sessionStorage.getItem('username')===null)return;
    console.log(window.sessionStorage.getItem('username'))
    setValues(prev=>({
      userName:window.sessionStorage.getItem('username'),
      password:window.sessionStorage.getItem('password')
    }))
    loginToPage(APIUrl, applicationID)
  },[])

  return (
    <div className='loginContainer'>
      <div className='infoText'>
        {mandantSettings!==undefined&&mandantSettings[language].welcomeText}
      </div>
      <div className='loginPanel'>
        <MDBRow className='mb-4'>
          <h3> Login</h3>
        </MDBRow>
        <MDBValidation 
        className='mb-3'  
        onSubmit={handleSubmit}>
        <MDBRow className='mb-4'> 
          <MDBValidationItem invalid feedback={feedback.userName}>
            <MDBInput 
            className='mb-4' 
            type='text' 
            id='loginForm' 
            label='Username' 
            name='userName'
            value={values.userName}
            required
            onChange={onChange}
            />
          </MDBValidationItem>
          </MDBRow>
          <MDBRow className='mb-3'>

          <MDBValidationItem 
          invalid 
          feedback={feedback.password}
          >
            <MDBInputGroup
            noBorder 
            textAfter={
                <MDBBtn 
                color='none'
                tag={'a'}
                onClick={()=>{changePasswordType(passwordType)}}>
                    {eye?
                    <MDBIcon fas icon='fas-solid fa-eye'/>
                    :
                    <MDBIcon fas icon='fas-solid fa-eye-slash'/>
                    }

                </MDBBtn>
            }>
              <div className='w-75'>
              <MDBInput 
                wrapperClass='col-auto'
                type={passwordType}
                label='Password' 
                name='password'
                size='md'
                value={values.password}
                required
                onChange={onChange}
              />
              </div>
            </MDBInputGroup>



            
          </MDBValidationItem>
          </MDBRow>

            <MDBRow className='mb-4'>
              <MDBCol className='d-flex justify-content-center'>
                <MDBCheckbox id='loginRememberCheck' label='Remember me' value={rememberUser} onClick={()=>{setRememberUser(!rememberUser)}}/>
              </MDBCol>
            </MDBRow>
            <MDBBtn	type='submit' style={{backgroundColor:mandantSettings.primaryColor}} block>Sign in</MDBBtn>
        </MDBValidation>
      </div>

    </div>

  )
}

export default Login