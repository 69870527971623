import { 
  MDBBtn, 
  MDBCard, 
  MDBCardBody, 
  MDBCardFooter,
  MDBCardHeader, 
  MDBCardImage, 
  MDBCardText,
  MDBCardTitle,
  MDBCol, 
  MDBIcon, 
  MDBListGroup, 
  MDBListGroupItem, 
  MDBPopover, 
  MDBPopoverBody, 
  MDBPopoverHeader, 
  MDBRow, 
  MDBTable, 
  MDBTabs, 
  MDBTabsContent, 
  MDBTabsItem, 
  MDBTabsLink, 
  MDBTabsPane,
  MDBTooltip,
  MDBInputGroup,
  MDBInput,
  MDBSpinner
} from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react'
import { addExistingElementToParent, addNewElement, addNewProperty, alterElement, createNewElement, findRelationTargets, getClassSchema, getElement, getSettings, removeElement } from '../../../functions/APICalls';
import './Editors.css';
import languages from '../../../languages/languages.json';
import { useNavigate, useParams } from 'react-router-dom';


function LocalizedMediaLanguageList({
    APIUrl,
    token,
    modelElement,
    schemeChilds,
    setModelElement,
    elementChildren,
    language
}) {

  const navigate = useNavigate();
  const {elementID} = useParams();


  const [existingListOriginal, setExistingListOriginal] = useState(undefined);
  const [existingList, setExistingList] = useState(undefined);
  const [searchExisting, setSearchExisting] = useState(undefined);
  const [existingListProgress, setExistingListProgress] = useState(false);


  const [localizedMediaLanguages, setLocalizedMediaLanguages] = useState([]);
  const [basicActive, setBasicActive] = useState('tab1');

  const [mediaListScheme, setMediaListScheme] = useState();
  const [mediaListSchemeLanguages, setMediaListSchemeLanguages] = useState();

  const [globalLanguages, setGlobalLanguages] = useState();

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  //Setup processs
  useEffect(()=>{
    if(modelElement===undefined)return;
    let mediaLanguageList = []
    if(modelElement.children[schemeChilds.relationName]!==undefined && modelElement.children[schemeChilds.relationName].content!==undefined){
      modelElement.children[schemeChilds.relationName].content.map((localizedMedia,mediaID)=>{ 
        getElement(APIUrl,token, localizedMedia.id)
        .then(data=>{
          console.log(data)
          getClassSchema(APIUrl,token,data.type)
          .then(schemeClass=>{
            //console.log(schemeClass)
            setMediaListScheme(schemeClass)
            schemeClass.childRelations.map(element=>{
              if(element.targetClassName === "I18nMediaList"){
                setMediaListSchemeLanguages(element)
              }
            })
          })
          if(data.children !== undefined && 
            data.children.media !==undefined &&
            data.children.media.content !== undefined
            ){
            data.children.media.content.map(I18nList=>{
              console.log(I18nList)
              getElement(APIUrl,token,I18nList.id)
              .then(returnedList=>{
                console.log(returnedList)
                if(returnedList!==undefined&&returnedList.children!==undefined&& returnedList.children.media!==undefined && returnedList.children.media.content!==undefined){
                  returnedList.children.media.content.map(images=>{
                    mediaLanguageList.push(images)
                    //console.log(images)
                    if(returnedList.properties.filter(prop=>prop.name==="locale")[0]!==undefined){
                      setLocalizedMediaLanguages(prev=>({
                        ...prev,
                        [returnedList.properties.filter(prop=>prop.name==="locale")[0].value]:{
                          id:returnedList.id,
                          children:mediaLanguageList,
                          validationResult:returnedList.validationResult

                        }
                      }))
                    }
                  })
                }else{
                  setLocalizedMediaLanguages({});
                  if(returnedList.properties.filter(prop=>prop.name==="locale")[0]===undefined)return;
                  setLocalizedMediaLanguages(prev=>({
                    ...prev,
                    [returnedList.properties.filter(prop=>prop.name==="locale")[0].value]:{
                      id:returnedList.id,
                      children:[],
                      validationResult:returnedList.validationResult
                    }
                  }))
                  getClassSchema(APIUrl,token,"LocalizedMediaList")
                  .then(schemeClass=>{
                    //console.log(schemeClass)
                    setMediaListScheme(schemeClass)
                    schemeClass.childRelations.map(element=>{
                      if(element.targetClassName === "I18nMediaList"){
                        setMediaListSchemeLanguages(element)
                      }
                    })
                  })
                }
                
              })
            })
          }else{
            // console.log(data)
            if(data.properties.filter(prop=>prop.name==="locale")[0]===undefined)return;
            setLocalizedMediaLanguages(prev=>({
              ...prev,
              [data.properties.filter(prop=>prop.name==="locale")[0].value]:{
                id:data.id,
                children:[],
                validationResult:data.validationResult
              }
            }))

          }
      })
    })
  }
  getSettings(APIUrl, token)
  .then(settings=>{
    // console.log(settings)
    let languages = settings.settings.project.filter(set=>set.key==="default.i18n.languages")[0].value.split(',')
    setGlobalLanguages(languages)
  })
  },[elementID])


  function addExistingElement(relationName,id){
    var activeLanguage = globalLanguages[parseInt(basicActive.charAt(basicActive.length-1))-1]

    console.log(localizedMediaLanguages[activeLanguage]);
    
    addExistingElementToParent(APIUrl, token, localizedMediaLanguages[activeLanguage].id, id, relationName)
    .then(data=>{
      console.log(data)
      //var activeLanguage = globalLanguages[parseInt(basicActive.charAt(basicActive.length-1))-1]
      //console.log(activeLanguage);
      //console.log(localizedMediaLanguages)
      if(localizedMediaLanguages[activeLanguage].children.length==0){
        setLocalizedMediaLanguages(prev=>({
          ...prev,
          [activeLanguage]:{
            ...prev[activeLanguage],
            children:[data]}
        }))
      }else{
        setLocalizedMediaLanguages(prev=>({
          ...prev,
          [activeLanguage]:{
            ...prev[activeLanguage],
            children:[...prev[activeLanguage].children, data]}
        }))

      }
      
      
      setExistingList(undefined)
    })


    
  }


  function removeItem(relationName,parentID, id){
    removeElement(APIUrl, token, parentID , relationName, id)
    .then(res=>{
      var activeLanguage = globalLanguages[parseInt(basicActive.charAt(basicActive.length-1))-1]
      let newItems = localizedMediaLanguages[activeLanguage].children.filter(elements=>elements.id!==id)
      setLocalizedMediaLanguages(prev=>({
        ...prev,
        [activeLanguage]:{
          ...prev[activeLanguage],
          children:newItems
        }
      }))
    })
  }


  function addLanguageToElement(language){
    addNewElement(APIUrl, token, elementID, "media")
    .then(newElement=>{
      getClassSchema(APIUrl, token, newElement.type).then(newSchema=>{
        setMediaListScheme(newSchema)
          newSchema.childRelations.map(element=>{
          if(element.targetClassName === "I18nMediaList"){
            setMediaListSchemeLanguages(element)
          }
        })
        // add the locale property for the new element
        addNewProperty(APIUrl, token, "locale", newElement.id, language)
        .then(prop=>{
          createNewElement(APIUrl, token, "MediaList_"+newElement.id, "I18nMediaList")
          .then(newMediaList=>{
            addNewProperty(APIUrl, token, "locale", newMediaList.id, language)
            .then(modifiedList=>{
              addExistingElementToParent(APIUrl, token, newElement.id, newMediaList.id, "media")
              .then(addedMediaElement=>{
                setLocalizedMediaLanguages(prev=>({
                  ...prev,
                  [language]:{
                    id:addedMediaElement.id,
                    children:[]
                  }
                }))
              })
            })
          })
        })

      })
      
    })
  }

  function selectFromExisting(relationName, localeID){
    setExistingListProgress(true);
    if(existingList!=undefined){
      setExistingList(undefined);
      setExistingListProgress(false);
    }else{
      setExistingList(undefined);
      setExistingListOriginal(undefined);
      findRelationTargets(APIUrl, token, localeID, relationName)
      .then(data=>{
        setExistingList(prev=>({[relationName]:data}));
        setExistingListOriginal(prev=>({[relationName]:data}));
        setSearchExisting(prev=>(undefined))
        setExistingListProgress(false);
      })
    }
  }

  function addElement(relationName, id, language){
    console.log(localizedMediaLanguages[language].id)
    createNewElement(APIUrl, token, "newMedia", "Media")
    .then(res=>{
      addExistingElementToParent(APIUrl, token, localizedMediaLanguages[language].id, res.id, "media")
      .then(data=>{
        setLocalizedMediaLanguages(prev=>({
          ...prev,
          [language]:{
            ...prev[language],
            children:[...prev[language].children, data]
          }
        }))
      })
    })
  }

  useEffect(()=>{
    if(localizedMediaLanguages===undefined)return;
    console.log(modelElement)
    console.log(localizedMediaLanguages);
    console.log(mediaListSchemeLanguages)
  },[localizedMediaLanguages])


  return (
    <MDBRow className='border my-3' style={{minHeight:"20rem"}}>
        <MDBRow  className='mt-3 p-0 ps-3'>
        {/* <MDBCol>
          <h5 key={"R1_Header"}>
            {String(schemeChilds.relationName).toUpperCase()}
          </h5>
        </MDBCol> */}
        <MDBCol offsetMd='8'>
        {(modelElement.validationResult!==undefined&&
            modelElement.validationResult.errorsMap.ChildMissingError!==undefined)&&
            modelElement.validationResult.errorsMap.ChildMissingError.map(validationError=>{
              
              if(validationError.relation === schemeChilds.relationName){
                return(
                  <MDBPopover dismiss color='none' tag={'a'} btnChildren={
                    <MDBIcon fas icon='fa-thin fa-circle-exclamation' style={{color:"lightcoral"}}/>
                  }>
                    <MDBPopoverHeader>
                      
                      {"ERROR: "+String(validationError.relation).toUpperCase()}
                    </MDBPopoverHeader>
                    <MDBPopoverBody>
                      {validationError.info.localizedTexts.filter(text=>text.locale===language).length>0&&validationError.info.localizedTexts.filter(text=>text.locale===language)[0].text}

                    </MDBPopoverBody>
                  </MDBPopover>
                )
              }
            })
          }
        </MDBCol>
        <hr  style={{width:"80%", marginLeft:"1%"}}/>
        </MDBRow>
        <MDBRow key={"R2"} className='ms-3'>
        {(localizedMediaLanguages!==undefined&&mediaListSchemeLanguages!==undefined)
        ?<>
        <MDBTabs className='mb-3' justify >
          {Object.keys(localizedMediaLanguages).map((language,languageID)=>{
            return(
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => handleBasicClick('tab'+(languageID+1))} active={basicActive === 'tab'+(languageID+1)}>
                    <>
                    {language}
                    </>
                  </MDBTabsLink>
                  
                </MDBTabsItem>
            )
          })}
          {Object.keys(localizedMediaLanguages).length < mediaListSchemeLanguages.targetMultiplicity.countMax &&
            <MDBTabsItem>
              <MDBTabsLink style={{backgroundColor:"rgba(181, 242, 162, 0.482)"}} onClick={() => handleBasicClick('tab_add')} active={basicActive === 'tab_add'}>
              {languages.de.addLanguage.toUpperCase()} <MDBIcon className='ps-1' fas icon='fa-solid fa-plus'/>
                
              </MDBTabsLink>
            </MDBTabsItem>
          } 

        </MDBTabs>
        <MDBTabsContent>
        {Object.keys(localizedMediaLanguages).map((language,languageID)=>{
          
          // console.log(mediaListSchemeLanguages)
            return(
                <MDBTabsPane show={basicActive===('tab'+(languageID+1))}>
                  
                  <MDBRow className='border p-3'>
                  <MDBCol className='px-3'>
                  <MDBRow >

                    <MDBCol md={2}>
                      <h6>{languages.de.mediaElements}</h6>
                    </MDBCol>
                    <MDBCol md={1}>
                      {(localizedMediaLanguages[language].validationResult!==undefined&&
                        localizedMediaLanguages[language].validationResult.errorsMap!==undefined&&
                        localizedMediaLanguages[language].validationResult.errorsMap.ChildMissingError!==undefined
                      )&&
                      localizedMediaLanguages[language].validationResult.errorsMap.ChildMissingError.map(missingMedia=>{
                        return(
                          <MDBPopover dismiss color='none' tag={'a'} btnChildren={
                            <MDBIcon fas icon='fa-thin fa-circle-exclamation' style={{color:"lightcoral"}}/>
                          }>
                            <MDBPopoverHeader>
                              
                              {"ERROR: "+String(missingMedia.errorType).toUpperCase()}
                            </MDBPopoverHeader>
                            <MDBPopoverBody>
                              {missingMedia.info.localizedTexts.filter(text=>text.locale===language).length>0&&missingMedia.info.localizedTexts.filter(text=>text.locale===language)[0].text}
        
                            </MDBPopoverBody>
                          </MDBPopover>

                        )
                      })
                      
                      }
                    </MDBCol>
                    <MDBCol md={1}>
                      <MDBBtn size='md' tag={'a'} color='none' onClick={()=>{
                        // removeItem(schemeChilds.relationName,localizedMediaLanguages[language].id, languageMediaContent.id)
                       console.log(modelElement.children[schemeChilds.relationName].content)
                       //Iterate all medialists and remove all of them
                       modelElement.children[schemeChilds.relationName].content.forEach(list=>{
                        console.log(list);
                        removeItem(schemeChilds.relationName, modelElement.id, list.id)
                       });
                        // removeItem(schemeChilds.relationName, modelElement.id, localizedMediaLanguages[language].id)
                      }}>
                        <MDBTooltip tag={'a'} title="Remove all Media Elements">
                          <MDBIcon fas icon='fa-light fa-remove'/>
                        </MDBTooltip>
                      </MDBBtn>
                      

                    </MDBCol>

                    <hr key={"R1_divider"} style={{width:"100%", marginLeft:"1%"}}/>
                    
                  {(localizedMediaLanguages[language].children!==undefined)&&
                    localizedMediaLanguages[language].children.map((languageMediaContent,languageMediaContentID)=>{
                      return(
                        <MDBCard
                        key={languageMediaContentID+"_languageMediaContent_"+language}
                        className="element-card"
                        onClick={(e)=>{
                          if(e.target.tagName==='A'|| e.target.tagName==='I'){
                            return
                          }
                          navigate(`/model/${languageMediaContent.id}`);                        
                        }}
                        >
                          {languageMediaContent.previewImage&&
                            <MDBCardImage src={`${APIUrl}/media/${languageMediaContent.previewImage}?token=${token}&preview=true&scaleLongSideSize=150`} position='top' />
                          }
                          <MDBCardTitle className=''>
                            {languageMediaContent.name!==undefined?languageMediaContent.name:"NAME"}
                          </MDBCardTitle>
                          <MDBCardBody >
                          <MDBListGroup flush light>
                            
                            <MDBListGroupItem>
                                {languageMediaContent.id}
                              </MDBListGroupItem>
                            {languageMediaContent.previewText &&
                              <MDBListGroupItem>
                                <MDBTooltip placement='bottom' tag='a' title={languageMediaContent.previewText}>
                                  <MDBCardText style={{maxHeight:"50px", overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}}>
                                    {languageMediaContent.previewText}
                                  </MDBCardText>
                                </MDBTooltip>
                              </MDBListGroupItem>
                            }
                            </MDBListGroup>
                            
                          </MDBCardBody>
                          <MDBCardFooter style={{height:"30%"}} className='d-flex align-items-center justify-content-center'>
                          {!schemeChilds.autoReferenceAllTargetClassInstances&&
                          <>
                            <MDBBtn size='sm' style={{position:"absolute", bottom:"0px",right:"0px", color:"lightcoral"}} tag={'a'} color='none' onClick={()=>{
                              removeItem(schemeChilds.relationName,localizedMediaLanguages[language].id, languageMediaContent.id)
                            }}>
                              <MDBTooltip tag={'a'} title="Remove this item">
                                <MDBIcon fas icon='fa-light fa-link-slash'/>
                              </MDBTooltip>
                            </MDBBtn>
                          </>
                          

                          }
                          </MDBCardFooter>

                        </MDBCard>

                      )
                    })
                    
                  }

                  {mediaListSchemeLanguages!==undefined&&
                  <>
                  {mediaListSchemeLanguages.targetMultiplicity.countMax>localizedMediaLanguages[language].children.length&&
                  <>
                  <MDBCard
                  onClick={()=>{
                    addElement("media", localizedMediaLanguages[language].id, language);
                              
                  }}
                  key={"media_addSymbol"}
                  className='element-card add-card'>
                    <MDBCardHeader>
                      {languages.de.addNewElement}
                      

                    </MDBCardHeader>
                    <MDBCardBody style={{height:"20%"}} className='d-flex align-items-center justify-content-center'>
                      <MDBIcon fas icon='fa-light fa-plus'/>
                    </MDBCardBody>

                  </MDBCard>
                  <MDBCard
                  onClick={()=>{
                    selectFromExisting("media", localizedMediaLanguages[language].id)
                  }}
                  key={"media_select_addSymbol"}
                  className='element-card add-card'>
                    <MDBCardHeader>
                      {languages.de.selectExistingElement}

                    </MDBCardHeader>
                    <MDBCardBody style={{height:"20%"}} className='d-flex align-items-center justify-content-center'>
                    {existingListProgress?
                      <MDBSpinner><span className='visually-hidden'>Loading...</span></MDBSpinner>
                      :<MDBIcon fas icon='fa-light fa-list'/>
                    }
                      
                    </MDBCardBody>


                  </MDBCard>
                  
                  </>
                  }
                  </>
                  }
                  </MDBRow >
                  </MDBCol>
                  {(existingList!==undefined && existingList[schemeChilds.relationName]!==undefined) &&
                    <MDBCol className='mb-3 select-table p-3' md={6}>
                      <MDBRow className='mb-3'>
                        <MDBCol md="8">
                        <MDBInputGroup
                        textAfter={
                          <>
                          <MDBCol md={"6"} className='me-2'>
                          <MDBBtn color='none' tag={'a'} 
                          onClick={()=>{
                            var filteredList = existingListOriginal[schemeChilds.relationName].filter((el)=>{if(el.name.includes(searchExisting)){return(el)}})
                            setExistingList(prev=>({[schemeChilds.relationName]:filteredList}))
                          }}>
                            <MDBIcon fas icon='fa-light fa-magnifying-glass'/>
                          </MDBBtn>
                          </MDBCol>
                          <MDBCol md={"6"} className='me-2'>
                          <MDBBtn color='none' tag={'a'} 
                          onClick={()=>{
                            setExistingList(prev=>({[schemeChilds.relationName]:existingListOriginal[schemeChilds.relationName]}))
                          }}>
                            <MDBIcon fas icon='fa-light fa-arrow-rotate-left'/>
                          </MDBBtn>

                          </MDBCol>


                          </>
                        }
                        >
                          <MDBInput type='text' label="search" value={searchExisting} onChange={(e)=>setSearchExisting(e.target.value)}/>

                        </MDBInputGroup>
                          
                        </MDBCol>
                      </MDBRow>
                      <MDBRow style={{height:"25vh", overflowY:"scroll"}}>
                        {existingList[schemeChilds.relationName].map((selectionPossibilities, possibilitieId)=>{
                          return(
                            <MDBCard style={{height:"200px", width:"200px"}} className='m-3' onClick={()=>(addExistingElement("media", selectionPossibilities.id))}>
                              <MDBCardImage src={`${APIUrl}/media/${selectionPossibilities.previewImage}?token=${token}&preview=true&scaleLongSideSize=300`} >

                              </MDBCardImage>
                              <MDBCardTitle>
                              {selectionPossibilities.name}
                              </MDBCardTitle>


                            </MDBCard>

                          );
                        })}


                        {/* <MDBTable striped bordered hover size="sm" >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Preview</th>
                            </tr>
                          </thead>
                          <tbody>
                            {existingList[schemeChilds.relationName].map((selectionPossibilities, possibilitieId)=>{
                              return(
                                <tr 
                                className={possibilitieId%2!==0?"grey-table-col":"white-table-col"} 
                                
                                onMouseEnter={(e)=>{
                                  
                                  e.target.parentElement.childNodes.forEach(childNode=>(childNode.classList.add("hovered")));
                                }}
                                onMouseLeave={(e)=>(e.target.parentElement.childNodes.forEach(childNode=>(childNode.classList.remove("hovered"))))}
                                onClick={()=>(addExistingElement("media", selectionPossibilities.id))}
                                >
                                  <td>
                                    {selectionPossibilities.id}
                                  </td> 
                                  <td>
                                    {selectionPossibilities.name}
                                  </td>
                                  <td>
                                  <img src={`${APIUrl}/media/${selectionPossibilities.previewImage}?token=${token}&preview=true&scaleLongSideSize=200`}/>
                                    
                                  </td>
                                    
                                </tr>
                              )
                            })}
                          </tbody>
                          </MDBTable> */}
                        </MDBRow>
                    </MDBCol>
              }
                  </MDBRow>

                </MDBTabsPane>
            )
          })}
          <MDBTabsPane show={basicActive===('tab_add')}>
          <MDBRow>
              {(globalLanguages!==undefined)&&
              <>
              {globalLanguages.map((lang,langID)=>{
                if(Object.keys(localizedMediaLanguages).includes(lang))return;
                
                return(
                  <MDBCard
                  className='element-card add-card'
                  key={lang+langID}
                  onClick={()=>{
                    addLanguageToElement(lang)
                  }}
                  >
                    <MDBCardHeader>
                      <MDBCardTitle>
                        {lang.toUpperCase()}
                      </MDBCardTitle>
                    </MDBCardHeader>
                  </MDBCard>
                )
              })}
              
              </>}
            </MDBRow>
          </MDBTabsPane>
        </MDBTabsContent>

        </>
        :
        <>
        <MDBTabs className='mb-3' justify>
          <MDBTabsItem>
            <MDBTabsLink style={{backgroundColor:"rgba(181, 242, 162, 0.482)"}} onClick={() => handleBasicClick('tab_add')} active={basicActive === 'tab_add'}>
              {languages.de.addLanguage.toUpperCase()} <MDBIcon className='ps-1' fas icon='fa-solid fa-plus'/>
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

          <MDBTabsContent>
          <MDBTabsPane show={basicActive===('tab_add')}>
            <MDBRow>
              {(globalLanguages!==undefined)&&
              <>
              {globalLanguages.map((lang,langID)=>{
                return(
                  <MDBCard
                  className='element-card add-card'
                  key={lang+langID}
                  onClick={()=>{
                    addLanguageToElement(lang)
                  }}
                  >
                    <MDBCardHeader>
                      <MDBCardTitle>
                        {lang.toUpperCase()}
                      </MDBCardTitle>
                    </MDBCardHeader>
                  </MDBCard>
                )
              })}
              
              </>}
            </MDBRow>

          </MDBTabsPane>
          </MDBTabsContent>
          </>
        }
        </MDBRow>
    </MDBRow>
  )
}

export default LocalizedMediaLanguageList