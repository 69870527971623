//Packages
import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow, MDBSpinner } from 'mdb-react-ui-kit';
import CookieConsent from "react-cookie-consent";

//CSS
import './App.css';

//Components
import Login from './components/Login/Login';
import RequireAuth from './components/Login/RequireAuth';
import Home from './components/Home/Home';
import User from './components/User/User';
import Data from './components/Data/Data';
import Release from './components/Release/Release';
import Scheme from './components/Scheme/Scheme';
import Settings from './components/Settings/Settings';
import Model from './components/Model/Model';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import { getMandant, getMandantLayout } from './functions/APICalls';
import ModelSearchView from './components/Model/SearchView/ModelSearchView';
import Impress from './components/Impress/Impress';


function App({
  APIUrl,
  path,
  appURL
}) {

//===Global variables to pass into components===//
  const [applicationID, setApplicationID] = useState();
  //User Settings
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  //Cookie Variable
  const [useCookies, setUseCookies] = useState();
  //Language.window.navigator.language
  const [language, setLanguage] = useState("en");
  //Global error messages
  const [globalError, setGlobalError] = useState()
  //layout data
  const [mandantSettings, setMandantSettings] = useState();
  //global propertie element
  const [globalProperties, setGlobalProperties] = useState();
  //Model Element variables
  const [modelElement, setModelElement] = useState();
  // const [elementProperties, setElementProperties] = useState();
  // const [elementChildren, setElementChildren] = useState();

  // searched Element
  const [searchedElements, setSearchedElements] = useState();
  

 

  useEffect(()=>{
    if(mandantSettings)return;
    console.log(APIUrl,appURL)
    getMandant(APIUrl, appURL)
    .then(data=>{
      setApplicationID(data.applicationID);
      data.mandantSettings.map(setting=>{
        if(setting.languageCode){
          setMandantSettings(prev=>({
            ...prev,
            [setting.languageCode]:{
              ...prev[setting.languageCode],
              [setting.key]:setting.value
            }
          }))
          
        }else{
          setMandantSettings(prev=>({
            ...prev,
            [setting.key]:setting.value
          }))
        }
        
      })
      return
    })
    .catch(error=>{
      setGlobalError(error.message);
    })

  },[])

  useEffect(()=>{
    console.log(mandantSettings);
    console.log(applicationID)
    // console.log(`${APIUrl}/webappmedia/${mandantSettings.mainImage}?applicationID=${applicationID}`)
  },[mandantSettings, applicationID])


  return (
    <div className="App">
      {/* <CookieConsent
        location="bottom"
        buttonText="Confirm"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{  fontSize: "10px" }}>This bit of text is smaller :O</span>
      </CookieConsent> */}
    {/*APP can only be shown if mandantSettings are loaded */}
    {applicationID!==undefined&&mandantSettings!==undefined?
      <>
        <NavBar
        mandantSettings={mandantSettings}
        applicationID={applicationID}
        APIUrl={APIUrl}
        language={language}
        token={token}
        setUser={setUser}
        user={user}
        />
        
        
        <main className='center'>
        <div 
          className='mandantBackground'
          style={{
            marginTop:"100px",
            width:"100vw",
            height:`${(mandantSettings.mainImageHeight)}px`,
            backgroundColor:mandantSettings.mainImage===undefined&&mandantSettings.primaryColor, 
            position:"absolute",
            zIndex:"2"
          }}
          >
            {mandantSettings.mainImage!==undefined&&
            <img src={`${APIUrl}/webappmedia/${mandantSettings.mainImage}?applicationID=${applicationID}`}></img>
            }

          </div>
          <div 
          className='foreGround'
          style={{
            marginTop:"0px",
            width:"100vw",
            height:"85vh",
            zIndex:"100",
            position:"absolute",
            padding:"50px 20px 20px 20px"
          }}
          >
            <Routes>
              {/* PUBLIC ROUTES */}
              <Route path='impress' element={
                <Impress/>
              }/>
              
              
              <Route path='login' element={
                <Login
                mandantSettings={mandantSettings}
                language={language}
                applicationID={applicationID}
                APIUrl={APIUrl}
                setUser={setUser}
                setToken={setToken}

                />
              }/>

              {/*  PRIVATE ROUTES */}
              <Route element={<RequireAuth user={user}/>}>
                <Route path='/' element={
                  <Home
                  APIUrl={APIUrl}
                  token={token}
                  setModelElement={setModelElement}
                  setGlobalProperties={setGlobalProperties}
                  />
                }/>
                <Route path='data' element={
                  <Data

                  />
                }/>
                <Route path='model'>
                  <Route path=':elementID' element={
                    <Model
                    APIUrl={APIUrl}
                    token={token}
                    modelElement={modelElement}
                    setModelElement={setModelElement}
                    globalProperties={globalProperties}
                    language={language}
                    setSearchedElements={setSearchedElements}
                    mandantSettings={mandantSettings}
                    />
                  }/>
                </Route>

                <Route path='search'>
                  <Route path=':searchName/:searchValue' element={
                    <ModelSearchView
                    APIUrl={APIUrl}
                    token={token}
                    modelElement={modelElement}
                    setModelElement={setModelElement}
                    globalProperties={globalProperties}
                    language={language}
                    searchedElements={searchedElements}
                    setSearchedElements={setSearchedElements}
                    mandantSettings={mandantSettings}
                    />
                  }/>
                </Route>



                <Route path='release' element={
                  <Release

                  />
                }/>
                <Route path='scheme' element={
                  <Scheme

                  />
                }/>
                <Route path='settings' element={
                  <Settings

                  />
                }/>
                <Route path='users' element={
                  <User

                  />
                }/>
                



              </Route>
            </Routes>
          </div>
        </main>
        <footer 
        style={{
          position:"absolute",
          width:"100vw",
          height:"3vh",
          bottom:"0px",
          borderTop:"black 2px solid"
        }}
        >
          <Footer
          APIUrl={APIUrl}
          token={token}
          modelElement={modelElement}
          setModelElement={setModelElement}
          />
        </footer>

      </>
      :
      <MDBContainer style={{height:"100vh"}}>
        <MDBRow>
        <h1>
          Loading 
        </h1>
        </MDBRow>
        <MDBRow>
        <MDBSpinner role='status'>
          <span className='visually-hidden'>Loading...</span>
        </MDBSpinner>
        </MDBRow>
      </MDBContainer>
    }
    </div>

  );
}

export default App;
