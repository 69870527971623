//Packages
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'mapbox-gl/dist/mapbox-gl.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//CSS
import './index.css';

//Components
import App from './App';

//Testing
import reportWebVitals from './reportWebVitals';



/*=== For testing @Andreas Bauer ===*/
//var APIUrl = "http://localhost:8080";

/*=== For testing @Andreas Wagner ===*/
// var APIUrl = "https://mupumodev.beauco.de";
var APIUrl = "https://outdoorapp.beauco.de";


/*=== For Developing ===*/
// var APIUrl = "";


var appURL = document.URL.replace('/login','');
if(APIUrl===""){
  APIUrl=appURL.substring(0, appURL.length-1);
}
appURL = appURL.replace("http://","").replace("https://","").replace("/","")
// const path = window.location.pathname;
const path = "/"



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/*" element= {<App APIUrl={APIUrl} path={path} appURL={appURL} />} />
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
