import { MDBRow, MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardImage, MDBCardTitle, MDBPopover, MDBPopoverBody, MDBPopoverHeader, MDBCol, MDBTable, MDBIcon, MDBBreadcrumb, MDBBreadcrumbItem, MDBInput, MDBInputGroup, MDBCardHeader, MDBSpinner } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { addNewProperty, getAllMediaElements, getSubpathMediaElements } from '../../../functions/APICalls';
import './Editors.css';
import languages from "../../../languages/languages.json";

function MediaEditor({
  APIUrl,
  token,
  modelElement,
  elementChildren,
  elementScheme,
  setModelElement
}) {


  const [mediaData, setMediaData] = useState();
  const [fileNamePropID, setFileNamePropID] = useState();
  
  const [mediaError, setMediaError] = useState("File Not found");

  const [mediaPath, setMediaPath] = useState([]);
  const [displayMediaElement, setDisplayMediaElement] = useState();
  const [searchMedia, setSearchMedia] = useState();

  const [selectFromExisting, setSelectFromExisting] = useState(false);
  const [uploadNew, setUploadNew] = useState(false);
  const [progress, setProgress] = useState(false);

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState();

  const [iconName, setIconName] = useState({});

  useEffect(()=>{
    if(modelElement===undefined)return;
    console.log(modelElement)
    modelElement.properties.map(element=>{
      if(element.name==="fileName"){
        setFileName(element.value);
      }
    })
  },[])

  useEffect(()=>{
    if(mediaData===undefined)return;
    mediaData.children.map(element=>{
      setIconName(prev=>({
        ...prev,
        [element.name]:"fa-folder"
      }))

    })
    
    //console.log(mediaData)
  },[mediaData])

  const openFolder = (e) =>{
    if(e==="home"){
      getAllMediaElements(APIUrl,token,false)
      .then(data=>{
        setMediaData(data)
        setMediaPath([]);
      })
    }else{
      getSubpathMediaElements(APIUrl,token,e,false)
      .then(data=>{
        setMediaData(data)
        setMediaPath(prev=>([...prev,e]));
      })


    }
  }

  const selectImage = async () => {
    let filePropID = modelElement.properties.filter(prop=>(prop.name==="fileName"))
    if(filePropID.length>0){
      filePropID = filePropID[0].id
      const res = await fetch(
        `${APIUrl}/alterProperty?token=${token}&value=${displayMediaElement.replace(".", "\u002E")}&propertyID=${filePropID}&name=fileName`,
        {
          method: 'POST'
      });
      if(res.ok){
        setFileName(displayMediaElement);
        setProgress(prev=>false);
      }else{
        setMediaError("Upload Failed");
        setProgress(prev=>false);
      }
    }else{
      addNewProperty(APIUrl, token, "fileName", modelElement.id, displayMediaElement.replace(".", "\u002E"))
      .then(newMediaName=>{
        setFileName(displayMediaElement)
        setProgress(prev=>false);

      })
    }
    
  }

  const filterMedia=()=>{
    let listBuffer = [];
    mediaData.children.forEach(mediaElement=>{
      if(mediaElement.name.includes(searchMedia)){
        listBuffer.push(mediaElement);
      }
    })
    setMediaData(prev=>({
      ...prev, 
      children:listBuffer
    }))

  }

  //handle the upload
  const handleSubmit = async (event) => {
    event.preventDefault();
    setProgress(prev=>true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    if(mediaPath==="home"){
      console.log(formData);
      const resp = await fetch(`${APIUrl}/media?token=${token}`,{
      method:"POST",
      body:formData,
      mode: 'no-cors'
      }).then(data=>{
        console.log(data);
        // setDisplayMediaElement(fileName);
        addNewProperty(APIUrl, token, "fileName", modelElement.id, file.name.replace(".", "\u002E"))
        .then(newMediaName=>{
          setFileName(file.name)
          setProgress(prev=>false);

        })
        
      })
      setProgress(false);

    }else{
      const resp = await fetch(`${APIUrl}/media?token=${token}&subpath=${mediaPath}`,{
        method:"POST",
        body:formData,
        mode: 'no-cors'
      }).then(data=>{
        console.log(data);
        addNewProperty(APIUrl, token, "fileName", modelElement.id, file.name.replace(".", "\u002E"))
        .then(newMediaName=>{
          setFileName(file.name);
          addNewProperty(APIUrl, token, "mediaID", modelElement.id, newMediaName.id.replace(".", "\u002E"))
          .then(newMediaName=>{
            setProgress(prev=>false);

          }).then(newMediaId=>{
            setProgress(prev=>false);

          })

        })
        


        }).catch(error=>{
          console.log(error)
        });


      setProgress(false);

    }
  };


  return (
    <MDBRow className='mb-4' style={{borderWidth:"1px", borderColor:"lightgrey", borderStyle:"solid", borderRadius:"5px", padding:"10px 10px 10px 25px"}}>
      <MDBRow className='mb-2' style={{padding:"15px 15px 15px 15px"}}>
        <MDBCol md={12} className='p-4'>
          {(fileName===undefined)?
          //FILE NOT FOUND CARD
          <MDBCard
          className={"element-card"}
          style={{backgroundColor:"lightcoral", padding:"10px 10px 10px 10px"}}
          >
            <MDBCardTitle>
              {mediaError}
            </MDBCardTitle>
            <MDBCardBody>
              <MDBRow className='mb-2 mt-3'>
                <MDBBtn color='secondary' onClick={()=>setUploadNew(!uploadNew)}>
                  {languages.de.upladNew}
                </MDBBtn>
              </MDBRow>
              <MDBRow className='mb-1'>
                <MDBBtn color='secondary' onClick={()=>{
                  getAllMediaElements(APIUrl,token,true)
                  .then(data=>{
                    setMediaData(data);
                    setSelectFromExisting(!selectFromExisting)

                  })

                }}>
                  {languages.de.selectExistingImage}
                </MDBBtn>
              </MDBRow>
              
            </MDBCardBody>


          </MDBCard>
          :
          <MDBRow>
          <MDBCol md={6}>
            <MDBCard 
              className={""}
              style={{width:"20rem"}}
              >
                <MDBCardImage 
                  position='top'
                  overlay
                  src={`${APIUrl}/media/${fileName}?token=${token}`}
                  />
                <MDBCardHeader>
                <MDBCardTitle key={"childElement_number_CardTitle"}>
                  {fileName}
                </MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody>
                  {languages.de.changeImage}
                  <MDBRow className='mt-4'>
                    <MDBCol>
                      <MDBBtn color='secondary' onClick={()=>setUploadNew(!uploadNew)}>
                        {languages.de.upladNew}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol>
                    <MDBBtn color='secondary' onClick={()=>{
                        getAllMediaElements(APIUrl,token,true)
                        .then(data=>{
                          setMediaData(data);
                          setSelectFromExisting(!selectFromExisting)

                        })
                      }}>
                        {languages.de.selectExistingImage}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

          </MDBRow>
          }
        </MDBCol>
        <MDBCol md={6} className="p-4">
          {uploadNew&&
          <MDBCard className='element-card wide' style={{backgroundColor:"lightgrey"}}>
            
            <MDBCardBody>
              <form onSubmit={handleSubmit}>
                <MDBRow className='mb-3'>
                <input
                size={"1"}
                type={'file'}
                onChange={(e) => setFile(e.target.files[0])}
                id="fileUpload" name="avatar"
                accept="image/png, image/jpeg, video/*" />
                </MDBRow>
                <MDBRow className='mb-3'>
                <MDBBtn type='submit' color='outline-secondary'>upload</MDBBtn>
                
                </MDBRow>
              </form>
            </MDBCardBody>
            {progress&&
            <MDBCardFooter>
              <MDBSpinner role='status'>
              <span className='visually-hidden'>Loading...</span>
              </MDBSpinner>
            </MDBCardFooter>
            }
          </MDBCard>
          }
        </MDBCol>
      </MDBRow>

      {selectFromExisting&&
      <MDBRow>
        <MDBRow>
          <MDBCol md={6}>
            <MDBBreadcrumb>
              <MDBBreadcrumbItem
              onClick={()=>{
                openFolder("home");
              }}
              className='breadcrumb-link'
              >Home
              </MDBBreadcrumbItem>
              {mediaPath!==undefined && mediaPath.map((el,id)=>{
                return(
                  <MDBBreadcrumbItem
                  key={id+"_breadcrumb"}
                  className='breadcrumb-link'
                  onClick={()=>{
                    var array = mediaPath.slice(0,id);
                    array.push(el)
                    setMediaPath(array);
                  }}
                  >{el}
                  </MDBBreadcrumbItem>
                )})}
            </MDBBreadcrumb>
          </MDBCol>
          <MDBCol md={6}>
            <MDBInputGroup  className='mb-3' textAfter={
              <>
              <MDBBtn 
              color="none"
              tag="a"
              className='mx-2 shadow-0'
              onClick={()=>{
                setSearchMedia("")
                
                getAllMediaElements(APIUrl,token,true)
                  .then(data=>{
                    setMediaData(data);

                  })
                
              }}
              >
                <MDBIcon fas icon='fa-solid fa-close'/>
              </MDBBtn>
              <MDBBtn 
              color="none"
              tag="a"
              className='mx-2 shadow-0'
              onClick={()=>{filterMedia()}}
              >
                <MDBIcon fas icon='fa-solid fa-search'/>
              </MDBBtn>

              </>
            }>
              <MDBInput required label={"search Media item"}  className='form-control' type='text' value={searchMedia} onChange={(e)=>setSearchMedia(e.target.value)}/>
            </MDBInputGroup>

          </MDBCol>
        </MDBRow>
        <MDBRow className='my-3'>
          <h5>{languages.de.subfolders}</h5>
          {mediaData!==undefined && mediaData.children!==undefined && mediaData.children.map(element=>{
                  if(element.type === "directory"){
                    
                    return(
                      <MDBCard 
                      key={"childElement_number_"+element.name}
                      onClick={(e)=>{element.type==="directory"&&openFolder(element.name)}}
                      className={"folder-card"}
                      >
                        <MDBCardBody key={"childElement_number_MDBCardBody_"+element.name}>
                          <MDBRow 
                          className='justify-content-center' 
                          onMouseEnter={()=>{
                            setIconName(prev=>({
                              ...prev,
                              [element.name]:"fa-folder-open"
                            }))
                          }}
                          onMouseLeave={()=>{
                            setIconName(prev=>({
                              ...prev,
                              [element.name]:"fa-folder"
                            }))
                          }}
                          >
                            <MDBIcon fas icon={`fa-regular ${iconName!==undefined?iconName[element.name]:"fa-folder"}`} size={'5x'}/>
                          </MDBRow>
                          <MDBRow className='justify-content-center'>
                          {element.name}
                          </MDBRow>
                        </MDBCardBody>
                      </MDBCard>

                    )
                  }

                })}


        </MDBRow>
        <MDBRow>
         <h5>{languages.de.files}</h5>


          <MDBCol md={7} style={{height:"45vh",overflowY:"scroll"}}>
            <MDBTable striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Preview</th>
                </tr>
              </thead>
              <tbody >
              {mediaData!==undefined && mediaData.children!==undefined && mediaData.children.map((element,elID)=>{
                if(element.type === "file"){
                  return(
                    <tr 
                      style={elID%2!==0?{backgroundColor:"lightgrey"}:{backgroundColor:"white"}} 
                      className="existing-table-column"
                      onMouseEnter={(e)=>{
                        e.target.style.cursor = "pointer";
                        e.target.parentElement.childNodes.forEach(childNode=>{
                          childNode.classList.add("hovered");
                          
                        });
                      }}
                      onMouseLeave={(e)=>(e.target.parentElement.childNodes.forEach(childNode=>{
                        childNode.classList.remove("hovered");
                        

                      }))}
                      onClick={(e)=>{
                        if(document.getElementsByClassName("selected").length>0){
                          Array.prototype.slice.call(document.getElementsByClassName("selected")).forEach(element=>{
                            element.classList.remove("selected")
                          })
                        }
                        e.target.parentElement.childNodes.forEach(childNode=>(childNode.classList.add("selected")));
                        setDisplayMediaElement(prev=>(element.name));

                      }}
                    >
                    <td>
                      {elID+1}
                    </td>
                    <td>
                      {element.name}
                    </td>
                    <td className='previewImageRow'>
                      <img src={`${APIUrl}/media/${element.name}?token=${token}&preview=true&scaleLongSideSize=300`} className='previewImage'/>
                    </td>
                    </tr>

                  )
                }

              })}
              </tbody>

            </MDBTable>
          </MDBCol>
          <MDBCol md={2}/>

          <MDBCol md={3}>
            <h5>Preview</h5>
            <MDBRow>
              <MDBCard>
                <MDBCardImage 
                  className='p-0'
                  fluid  
                  position='top'
                  src={(displayMediaElement===undefined)
                    ?''
                    :(mediaPath.length===0)
                    ?`${APIUrl}/media/${displayMediaElement}?token=${token}`
                    :`${APIUrl}/media/${mediaPath}/${displayMediaElement}?token=${token}`
                  }
                  />
                </MDBCard>
              </MDBRow>

              <MDBRow>
                <MDBBtn color='outline-success' onClick={()=>(selectImage())}>
                  <MDBIcon fas icon='fa-solid fa-upload'/>

                </MDBBtn>
              </MDBRow>

          </MDBCol>
        </MDBRow>
      </MDBRow>}
    </MDBRow>
  )
}

export default MediaEditor