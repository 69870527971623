import { 
  MDBCard, 
  MDBCardBody, 
  MDBCardHeader, 
  MDBCol, 
  MDBContainer, 
  MDBIcon, 
  MDBRow,
  MDBBtn,
  MDBCardFooter
} from 'mdb-react-ui-kit'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getGlobalProperties, getModelRoot } from '../../functions/APICalls'

//Css imports 
import './Home.css';
import languages from '../../languages/languages.json'

function Home({
  token,
  APIUrl,
  setModelElement,
  setGlobalProperties
}) {

  const navigate = useNavigate()

  function openModelRoot(){
    // getGlobalProperties(APIUrl, token)
    // .then(data=>{
    //   setGlobalProperties(data);
    // })

    getModelRoot(APIUrl, token)
    .then(data=>{
      // setModelElement(data);
      navigate(`/model/${data.id}`)
    })

  }


  return (
    <div className='center-Panel'>
      <MDBContainer >
        <MDBRow className='mb-2 m-4 py-4'>
          <MDBCol>
            <MDBCard
            shadow='0'
            background='white'
            border='dark'
           
            >
              <MDBCardHeader className='text-center'>
                User
              </MDBCardHeader>
              <MDBCardBody className='text-center'>
                <MDBIcon fas icon='fa-light fa-user' className='m-1' size={'8x'}/>
              </MDBCardBody>
              <MDBCardFooter className='text-center'>
                <MDBBtn color='light' className='m-3' style={{ color: 'black' }}  onClick={()=>navigate('/user')} >
                {languages.de.open}
                </MDBBtn>
              </MDBCardFooter>

            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard
            shadow='0'
            background='white'
            border='dark'
            >
              <MDBCardHeader className='text-center'>
                Release
              </MDBCardHeader>
              <MDBCardBody className='text-center'>
                <MDBIcon fas icon='fa-sharp fa-solid fa-repeat' className='m-1' size={'8x'}/>
              </MDBCardBody>
              <MDBCardFooter className='text-center'>
                <MDBBtn color='light' className='m-3' style={{ color: 'black' }}  onClick={()=>navigate('/release')} >
                {languages.de.open}
                </MDBBtn>
              </MDBCardFooter>


            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard
            shadow='0'
            background='white'
            border='dark'
            >
              <MDBCardHeader className='text-center'>
                Model
              </MDBCardHeader>
              <MDBCardBody className='text-center'>
                <MDBIcon fas icon='fa-light fa-pencil' className='m-1' size={'8x'}/>
              </MDBCardBody>
              <MDBCardFooter className='text-center'>
                <MDBBtn color='light' className='m-3' style={{ color: 'black' }}  onClick={()=>openModelRoot()} >
                {languages.de.open}
                </MDBBtn>
              </MDBCardFooter>

            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

export default Home