import { MDBCol, MDBContainer, MDBIcon, MDBPopover, MDBPopoverBody, MDBPopoverHeader, MDBRow } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { stringEditor, colorEditor, numberEditor, rangeNumberEditor, coordinateProperties, stringIDEditor, stringMarkdownEditor } from './propertieEditors'

function Properties({
    APIUrl,
    token,
    globalProperties,
    modelElement,
    elementProperties,
    language
}) {

    const {elementID} = useParams()
    const [propertie, setPropertie] = useState({});

    const [errors, setErrors] =  useState({});

    const [pickerShow, setPickerShow] = useState(false);
    const [globalColors, setGlobalColors] = useState();

    useEffect(()=>{
      if(modelElement===undefined)return;
      setPropertie({});
      setPickerShow();
      setErrors({})
      setPropertie(prev=>({
        ["name"]:{
          value: modelElement.name,
          id: modelElement.id,
          resetValue: modelElement.name
        }
      }))
      if(elementProperties===undefined){return};
      modelElement.properties.forEach(element => {
        setPropertie(prev=>({
          ...prev,
          [String(element.name).toLowerCase()]:{
            value: (element.value===null)?"":element.value,
            id: element.id,
            resetValue:(element.value===null)?"":element.value
          }
        }))
        setPickerShow((prev=>({
          ...prev,
          [String(element.name).toLowerCase()]:false
        })))
      });
      
      
    },[elementProperties]);


    useEffect(()=> {
      if(errors===undefined)return;
      //console.log(errors)
    },[errors])
    

    function returnEmptyValueWhenUndefined(input){
      if(input===undefined){
        return ""
      }else{
        return input.value
      }
    }

    function returnEmptyIDWhenUndefined(input){
      if(input===undefined){
        return ""
      }else{
        return input.id
      }
    }

    function returnEmptyResetValueWhenUndefined(input){
      if(input===undefined){
        return ""
      }else{
        return input.resetValue
      }
    }

    function returnErrorsWhenUndefined(input){
      if(input===undefined){
        return ""
      }else{
        return input
      }
    }
  

  return (
    <MDBContainer fluid>
      <h3>{modelElement===undefined?"":(modelElement.name!== "")?modelElement.name:modelElement.id}</h3>
        <MDBRow className='border p-3 my-3' fluid>
          <MDBRow className='mb-2' fluid>
            <MDBCol md={11}>
              <h4>Properties</h4>
            </MDBCol>
            <MDBCol className='my-0'>
              {(modelElement!==undefined&&modelElement.validationResult!==undefined&&
                modelElement.validationResult.errorsMap!==undefined&&
                modelElement.validationResult.errorsMap.MissingPropertyError!==undefined)&&
                <MDBPopover dismiss color='none' tag={'a'} btnChildren={
                  <MDBIcon fas icon='fa-thin fa-circle-exclamation' style={{color:"lightcoral"}}/>
                }>
                  <MDBPopoverHeader>
                    ERROR: PROPERTIES
                  </MDBPopoverHeader>
                  <MDBPopoverBody style={{maxHeight:"20vh",overflowY:"scroll"}}>
                    <ul>
                      {modelElement.validationResult.errorsMap.MissingPropertyError.map(propertyError=>{
                        return(
                          <li>
                            {propertyError.info.localizedTexts.filter(texts=>texts.locale===language)[0].text}
                          </li>
                        )
                      })}
                    </ul>
                  </MDBPopoverBody>
                </MDBPopover>
                
                }
            </MDBCol>
          </MDBRow>
        {(modelElement!==undefined && elementProperties!==undefined && pickerShow!==undefined)?
        <>
        <MDBRow className='mb-2 my-2 border-bottom'>
          { 
          stringIDEditor(
            "ID",
            modelElement.id
          )
          }
          {stringEditor(
            APIUrl,
            token,
            elementID,
            returnEmptyResetValueWhenUndefined(propertie[String("name").toLowerCase()]),
            returnEmptyIDWhenUndefined(propertie[String("name").toLowerCase()]),
            "name",
            returnEmptyValueWhenUndefined(propertie["name"]),
            (e)=>{
              setPropertie(prev=>({
                ...prev,
                [String("name").toLowerCase()]:{
                  ...prev[String("name").toLowerCase()],
                  value:e
                }
              }))
            },
            returnErrorsWhenUndefined(errors[String("name").toLowerCase()]),
            (err)=>{
              setErrors(prev=>({ 
                ...prev,
                [String("name").toLowerCase()]:err
              }))
            }
          )}
        </MDBRow>
        <MDBRow className='mb-2 my-2'>

          {elementProperties.map(schemeProperty=>{
            if(schemeProperty.name==="id"||schemeProperty.name==="fileName" || schemeProperty.name==="coordinates")return;
            if(schemeProperty.type === "String"){
              if(schemeProperty.name==="text"){
                return(
                  stringMarkdownEditor(
                    APIUrl,
                    token,
                    elementID,
                    returnEmptyResetValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    returnEmptyIDWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    schemeProperty.name,
                    returnEmptyValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    (e)=>{
                      setPropertie(prev=>({
                        ...prev,
                        [String(schemeProperty.name).toLowerCase()]:{
                          ...prev[String(schemeProperty.name).toLowerCase()],
                          value:e
                        }
                      }))
                    },
                    returnErrorsWhenUndefined(errors[String(schemeProperty.name).toLowerCase()]),
                    (err)=>{
                      setErrors(prev=>({
                        ...prev,
                        [String(schemeProperty.name).toLowerCase()]:err
                      }))
                    }
                  ))
                
              }else{
                return(
                  stringEditor(
                    APIUrl,
                    token,
                    elementID,
                    returnEmptyResetValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    returnEmptyIDWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    schemeProperty.name,
                    returnEmptyValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    (e)=>{
                      setPropertie(prev=>({
                        ...prev,
                        [String(schemeProperty.name).toLowerCase()]:{
                          ...prev[String(schemeProperty.name).toLowerCase()],
                          value:e
                        }
                      }))
                    },
                    returnErrorsWhenUndefined(errors[String(schemeProperty.name).toLowerCase()]),
                    (err)=>{
                      setErrors(prev=>({
                        ...prev,
                        [String(schemeProperty.name).toLowerCase()]:err
                      }))
                    }
                  ))
              }
              
              
            }else if(schemeProperty.type === "int"){
              return(
                numberEditor(
                  APIUrl,
                  token,
                  elementID,
                  returnEmptyResetValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                  returnEmptyIDWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                  schemeProperty.name,
                  returnEmptyValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                  (e)=>{
                    setPropertie(prev=>({
                      ...prev,
                      [String(schemeProperty.name).toLowerCase()]:{
                        ...prev[String(schemeProperty.name).toLowerCase()],
                        value:e
                      }
                    }))
                  },
                  returnErrorsWhenUndefined(errors[String(schemeProperty.name).toLowerCase()]),
                    (err)=>{
                      setErrors(prev=>({
                        ...prev,
                        [String(schemeProperty.name).toLowerCase()]:err
                      }))
                    }
                ))
            }else if(schemeProperty.type === "Color"){
              return(
                colorEditor(
                  APIUrl,
                  token,
                  elementID,
                  schemeProperty.group,
                  globalColors,
                  setGlobalColors,
                  returnEmptyResetValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                  returnEmptyIDWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                  schemeProperty.name,
                  returnEmptyValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                  (e)=>{
                    setPropertie(prev=>({
                      ...prev,
                      [String(schemeProperty.name).toLowerCase()]:{
                        ...prev[String(schemeProperty.name).toLowerCase()],
                        value:e
                      }
                    }))
                  },
                  pickerShow[String(schemeProperty.name).toLowerCase()],
                  (e)=>{
                    setPickerShow(prev=>({
                      ...prev,
                      [String(schemeProperty.name).toLowerCase()]:e
                    }))
                  },
                  returnErrorsWhenUndefined(errors[String(schemeProperty.name).toLowerCase()]),
                    (err)=>{
                      setErrors(prev=>({
                        ...prev,
                        [String(schemeProperty.name).toLowerCase()]:err
                      }))
                    }
                  
                ))
            }else{
              if(schemeProperty.name==="text"){
                return(
                  stringMarkdownEditor(
                    APIUrl,
                    token,
                    elementID,
                    returnEmptyResetValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    returnEmptyIDWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    schemeProperty.name,
                    returnEmptyValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    (e)=>{
                      setPropertie(prev=>({
                        ...prev,
                        [String(schemeProperty.name).toLowerCase()]:{
                          ...prev[String(schemeProperty.name).toLowerCase()],
                          value:e
                        }
                      }))
                    },
                    returnErrorsWhenUndefined(errors[String(schemeProperty.name).toLowerCase()]),
                    (err)=>{
                      setErrors(prev=>({
                        ...prev,
                        [String(schemeProperty.name).toLowerCase()]:err
                      }))
                    }
                  ))
                
              }else{
                return(
                  stringEditor(
                    APIUrl,
                    token,
                    elementID,
                    returnEmptyResetValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    returnEmptyIDWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    schemeProperty.name,
                    returnEmptyValueWhenUndefined(propertie[String(schemeProperty.name).toLowerCase()]),
                    (e)=>{
                      setPropertie(prev=>({
                        ...prev,
                        [String(schemeProperty.name).toLowerCase()]:{
                          ...prev[String(schemeProperty.name).toLowerCase()],
                          value:e
                        }
                      }))
                    },
                    returnErrorsWhenUndefined(errors[String(schemeProperty.name).toLowerCase()]),
                      (err)=>{
                        setErrors(prev=>({
                          ...prev,
                          [String(schemeProperty.name).toLowerCase()]:err
                        }))
                      }
                  ))
              }
            }
            // }
          })}
          </MDBRow>
        </>
        :
        <>
        {modelElement!==undefined&&
        <MDBRow className='mb-2 border-bottom'>
          { 
          stringIDEditor(
            "ID",
            modelElement.id
          )
          }
          {stringEditor(
            APIUrl,
            token,
            elementID,
            returnEmptyResetValueWhenUndefined(propertie[String("name").toLowerCase()]),
            returnEmptyIDWhenUndefined(propertie[String("name").toLowerCase()]),
            "name",
            returnEmptyValueWhenUndefined(propertie["name"]),
            (e)=>{
              setPropertie(prev=>({
                ...prev,
                [String("name").toLowerCase()]:{
                  ...prev[String("name").toLowerCase()],
                  value:e
                }
              }))
            },
            returnErrorsWhenUndefined(errors[String("name").toLowerCase()]),
            (err)=>{
              setErrors(prev=>({ 
                ...prev,
                [String("name").toLowerCase()]:err
              }))
            }
          )}
        </MDBRow>
        }
        </>
        }
        </MDBRow>
        
        
    </MDBContainer>
  )
}

export default Properties