import { 
  MDBContainer, 
  MDBNavbar,  
  MDBNavbarToggler, 
  MDBNavbarBrand, 
  MDBNavbarLink,
  MDBIcon, 
  MDBCollapse,
  MDBNavbarItem,
  MDBNavbarNav,
  MDBBtn} from 'mdb-react-ui-kit'
import React, {useState} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { getModelRoot } from '../../functions/APICalls';

function NavBar({
  mandantSettings,
  applicationID,
  APIUrl,
  language,
  token,
  setUser,
  user
}) {

  const navigate = useNavigate();

  const [showNav, setShowNav] = useState(false);

  const logout = () =>{
    console.log("Logouts")
    window.sessionStorage.removeItem('user');
    setUser(undefined);
    window.sessionStorage.removeItem("username");
    window.sessionStorage.removeItem("password")
    navigate("/");

  }

  return (
    <nav 
    style={{
      width:"100vw", 
      height:"7vh",
      paddingTop:"1%"

    }}>
      
      
      <MDBNavbar sticky expand="xxl" light bgColor={"light"} >
        
        <MDBContainer fluid>
          <MDBNavbarBrand style={{marginBottom:"-2%"}}>
          <img
              src={`${APIUrl}/webappmedia/${mandantSettings.headerImage}?applicationID=${applicationID}`}
              height={`${mandantSettings.headerImageHeight}px`}
              alt='LOGO of the Project'
              loading='lazy'
            />
            <p style={{scale:"0.5"}} className='text-break'>
              {mandantSettings[language].projectInfo.replace(/(\\n)/g,'\n')}
            </p>
          </MDBNavbarBrand>

          <MDBNavbarToggler
          type='button'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showNav}  >
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
            {(user!==undefined)&&<MDBNavbarItem className='me-2 me-lg-0  '>
              <MDBBtn  color='light' className='m-1 shadow-0 bg-transparent' style={{ color: 'black' }} onClick={()=>navigate('/')}>
                <MDBIcon fas icon='fa-light fa-house' className='m-1' />
                Home
              </MDBBtn>
            </MDBNavbarItem>}
            {(user!==undefined&&user.rights.includes("ManageReleases"))&&<MDBNavbarItem className='me-2 me-lg-0 '>
              <MDBBtn color='light' className='m-1 shadow-0 bg-transparent' style={{ color: 'black' }} onClick={()=>navigate('/release')}>
                <MDBIcon fas icon='fa-sharp fa-solid fa-repeat' className='m-1'/>
                Release
              </MDBBtn>
            </MDBNavbarItem>}
            {(user!==undefined&&user.rights.includes("WriteModel"))&&<MDBNavbarItem className='me-2 me-lg-0 '>
              <MDBBtn  color='light' className='m-1 shadow-0 bg-transparent' style={{ color: 'black' }} onClick={()=>{
                getModelRoot(APIUrl, token)
                .then(root=>navigate(`/model/${root.id}`))
                }}>
                <MDBIcon fas icon='fa-light fa-pencil' className='m-1'/>
                Model
              </MDBBtn>
            </MDBNavbarItem>}
            {(user!==undefined&&user.rights.includes("ImportData"))&&<MDBNavbarItem className='me-2 me-lg-0 '>
              <MDBBtn  color='light' className='m-1 shadow-0 bg-transparent' style={{ color: 'black' }} onClick={()=>navigate('/data')}>
                <MDBIcon fas icon='fa-light fa-table' className='m-1'/>
                Data
              </MDBBtn>
            </MDBNavbarItem>}
            {(user!==undefined&&user.rights.includes("WriteSchema"))&&<MDBNavbarItem className='me-2 me-lg-0 '>
              <MDBBtn color='light' className='m-1 shadow-0 bg-transparent' style={{ color: 'black' }} onClick={()=>navigate('/schema')}>
                <MDBIcon fas icon='fa-light fa-gem' className='m-1'/>
                Scheme
              </MDBBtn>
            </MDBNavbarItem>}
            {(user!==undefined&&user.rights.includes("ManageUsers"))&&<MDBNavbarItem className='me-2 me-lg-0 '>
              <MDBBtn  color='light' className='m-1 shadow-0 bg-transparent' style={{ color: 'black' }} onClick={()=>navigate('/user')}>
                <MDBIcon fas icon='fa-light fa-user' className='m-1'/>
                User
              </MDBBtn>
            </MDBNavbarItem>}
            {(user!==undefined&&user.rights.includes("EditProjectSettings"))&&<MDBNavbarItem className='me-2 me-lg-0'>
              <MDBBtn  color='light' className='m-1 shadow-0 bg-transparent' style={{ color: 'black' }} onClick={()=>navigate('/settings')}>
                <MDBIcon fas icon='fa-light fa-gear' className='m-1'/>
                Settings
              </MDBBtn>
            </MDBNavbarItem>}
            
         
          </MDBNavbarNav>
          <MDBBtn  color='light' className='m-3 shadow-0 bg-transparent' style={{ color: 'black' }} >
            <MDBIcon fas icon='fa-light fa-globe' size='lg' className='m-2' />
          </MDBBtn>
          <MDBBtn  color='light' className='m-3 shadow-0 bg-transparent' style={{ color: 'black' }} onClick={()=>(logout())}>
            <MDBIcon fas icon='fa-light fa-arrow-right-from-bracket' size='lg' className='m-2'/>
          </MDBBtn>
        </MDBCollapse>




        </MDBContainer>
      </MDBNavbar>

      <div 
      className='navBackground' 
      style={{
        position:"relative",
        width:"100vw",
        height:"20px",
        backgroundColor:mandantSettings.primaryColor,
        
      }}/>
      
    
      

    </nav>
  )
}

export default NavBar