/*===Mandant functions===*/
//Function for getting the mandant data and layout
export async function getMandant(APIUrl, appUrl){
    let res = await fetch(`${APIUrl}/getMandantSettingsByBaseURL?baseURL=${appUrl}`);
    let data = await res.json();
    if(res.ok){
        return data;
    }else{
        throw new Error(data.error)
    }
}
//function for logging in
export async function login(APIUrl, appID, username, password){
    let res = await fetch(`${APIUrl}/login?projectID=${appID}&pwd=${password}&user=${username}`);
    let data = await res.json();
    if(res.ok){
        return data;
    }else{
        throw new Error(data.error)
    }
}

export async function getSettings(APIUrl, token){
    let res = await fetch(`${APIUrl}/getSettings?token=${token}`);
    let data = await res.json();
    if(res.ok){
        return data;
    }else{
        throw new Error(data.error)
    }
}

/*===Propertie functions===*/
//function for getting the globalPropertieElement


export async function createProperty(APIUrl, token, name, parentID, value){
    const res = await fetch(`${APIUrl}/createProperty?propertyName=${name}&parentID=${parentID}&token=${token}&value=${value}`);
    const data = await res.json();
    if(res.ok){
        return data;
    }else{
        throw new Error(data.error)
    }
}

export async function getGlobalProperties(APIUrl, token, group){
    const res = await fetch(`${APIUrl}/findGlobalProperties?token=${token}&group=${group}`);
    const data = await res.json();
    if (res.ok) {
        return data
    } else {
        throw new Error(data);
    }

}

export async function addProperty(APIUrl, token, name, parentID){
    const res = await fetch(`${APIUrl}/addProperty?token=${token}&relationName=${name}&parentElementID=${parentID}`);
    const data = await res.json();
    if(res.ok){
        return data;
    }else{
        throw new Error(data)
    }
}

export async function addNewProperty(APIUrl, token, name, parentID, value){
    const res = await fetch(`${APIUrl}/addProperty?token=${token}&relationName=${name}&parentElementID=${parentID}&value=${value}`);
    const data = await res.json();
    if(res.ok){
        return data;
    }else{
        throw new Error(data)
    }
}


/*=POST function=*/
export async function alterProperty(APIUrl, token, propertyID, name, value){
    const res = await fetch(`${APIUrl}/alterProperty?propertyID=${propertyID}&token=${token}&value=${value}&name=${name}`,{method: 'POST',});
    if(res.ok){
        return res;
    }else{
        throw new Error(res)
    }
}

export async function alterPropertyWithoutName(APIUrl, token, propertyID, value){
    const res = await fetch(`${APIUrl}/alterProperty?propertyID=${propertyID}&token=${token}&value=${value}`,{method: 'POST',});
    if(res.ok){
        return res;
    }else{
        throw new Error(res)
    }
}



export async function removeProperty(APIUrl, token, name, parentID){
    const res = await fetch(`${APIUrl}/removeProperty?relationName=${name}&parentElementID=${parentID}&token=${token}`,{method: 'POST',});
    if(res.ok){
        return res;
    }else{
        throw new Error(res)
    }
}




/*===Schema functions ===*/
//function for getting the appScheme
export async function getSchema(APIUrl, token){
    const res = await fetch(`${APIUrl}/getSchema?token=${token}`);
    const data = await res.json();
    if(res.ok){
        return data;
    }else{
        throw new Error(data.error)
    }
}

export async function getSchemaClassTypes(APIUrl, token){
    const res = await fetch(`${APIUrl}/getSchemaClassTypes?token=${token}`);
    const data = await res.json();
    if(res.ok){
        return data;
    }else{
        throw new Error(data.error)
    }
}

export async function getClassSchema(APIUrl, token, classType){
    const res = await fetch(`${APIUrl}/getSchemaClass?token=${token}&classType=${classType}`);
    const data = await res.json();
    if(res.ok){
        return data;
    }else{
        throw new Error(data.error)
    }
}


/*===Element functions===*/
    /*=GET Functions=*/
    export async function getModelRoot(APIUrl, token){
        const res = await fetch(`${APIUrl}/getRootElement?token=${token}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }

    export async function getNextPage(APIUrl, token, parent, relation, nextPageNumber, pagesize){
        const res = await fetch(`${APIUrl}/getChildren?token=${token}&parentID=${parent}&relation=${relation}&page=${nextPageNumber}&pagesize=${pagesize}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }

    export async function getElement(APIUrl, token, elementID){
        const res = await fetch(`${APIUrl}/getElement?token=${token}&id=${elementID}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }

    export async function createNewElement(APIUrl, token, name, classType){
        const res = await fetch(`${APIUrl}/createElement?token=${token}&name=${name}&classType=${classType}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }

    export async function addNewElement(APIUrl, token, parentID, relationName){
        const res = await fetch(`${APIUrl}/addElement?token=${token}&relationName=${relationName}&parentID=${parentID}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }



    export async function addExistingElementToParent(APIUrl, token, parentID, childID, relationName){
        const res = await fetch(`${APIUrl}/addElement?token=${token}&relationName=${relationName}&parentID=${parentID}&childElementID=${childID}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }

    export async function findRelationTargets(APIUrl, token, elementID, relationName){
        const res = await fetch(`${APIUrl}/findRelationTargets?token=${token}&relation=${relationName}&elementID=${elementID}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }

    export async function findElementFromAll(APIUrl, token, searchValue, page){
        const res = await fetch(`${APIUrl}/find?token=${token}&value=${searchValue}&page=${page}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }

    export async function findElementMultiple(APIUrl, token, searchField, searchValue, page){
        const res = await fetch(`${APIUrl}/findElementMultiple?token=${token}&fields=${searchField}&value=${searchValue}&page=${page}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }



    /*=POST function=*/
    export async function removeElement(APIUrl, token, parentElement, relation, childElement){
        const res = await fetch(`${APIUrl}/removeElement?token=${token}&parentElementID=${parentElement}&relationName=${relation}&childElementID=${childElement}`,{method:"POST"});
        if(res.ok){
            return res;
        }else{
            throw new Error(res)
        }
    }

    export async function deleteElement(APIUrl, token, elementID, autoRemove){
        const res = await fetch(`${APIUrl}/deleteElement?token=${token}&elementID=${elementID}&autoRemoveFromParents=${autoRemove}`,{method:"POST"});
        if(res.ok){
            return res;
        }else{
            throw new Error(res)
        }
    }

    export async function alterElement(APIUrl, token, elementID, name){
        const res = await fetch(`${APIUrl}/alterElement?token=${token}&elementID=${elementID}&name=${name}`,{method:"POST"});
        if(res.ok){
            return res;
        }else{
            throw new Error(res)
        }
    }


    /*===MEDIA FUNCTIONS===*/
    //GET functions

    export async function getAllMediaElements(APIUrl, token, include){
        const res = await fetch(`${APIUrl}/media/list?token=${token}&includeSubfolders=${include}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }

    export async function getSubpathMediaElements(APIUrl, token,mediaPath, include){
        const res = await fetch(`${APIUrl}/media/list?token=${token}&subpath=${mediaPath}&includeSubfolders=${include}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }

    export async function getMediaElements(APIUrl, token, path){
        const res = await fetch(`${APIUrl}/file/${path}?token=${token}`);
        const data = await res.json();
        if(res.ok){
            return data;
        }else{
            throw new Error(data.error)
        }
    }

