import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import SearchBar from './SubComponents/SearchBar';
import Properties from './SubComponents/Properties';
import EditorSelector from './Editors/EditorSelector';


//css imports
import './Model.css'

//API MEthods
import { getClassSchema, getElement, getModelRoot } from '../../functions/APICalls'



function Model({
  APIUrl,
  token,
  modelElement,
  globalProperties,
  setModelElement,
  language,
  setSearchedElements,
  mandantSettings
}) {

  const {elementID} = useParams();
  
  //Variables for the properties and the child relations
  const [elementProperties, setElementProperties] = useState();
  const [elementChildren, setElementChildren] = useState();
  const [elementScheme, setElementScheme] = useState();
  const [root, setRoot] = useState();
  const [autoReferenced, setAutoReferenced] = useState(true);


  function loadElement(element){

    getModelRoot(APIUrl, token)
    .then(root=>{setRoot(root)});
    
    getClassSchema(APIUrl, token, element.type)
    .then(schema=>{

      setElementScheme(schema);
      setElementProperties(schema.variables);
      if(schema.childRelations!==undefined){
        setElementChildren(schema.childRelations);
      }else{
        setElementChildren(undefined);
      }
    })
  }

  useEffect(()=>{
    if(elementID===undefined)return;
    getElement(APIUrl,token, elementID)
      .then(data=>{
        setElementProperties(undefined);
        setElementScheme(undefined);
        setModelElement(data)
        loadElement(data)

      })
  },[elementID])




  return (
    <div className='center-panel-model'>
      
        <SearchBar
        APIUrl={APIUrl}
        token={token}
        setSearchedElements={setSearchedElements}
        mandantSettings={mandantSettings}
        />

        <Properties
        globalProperties={globalProperties}
        modelElement={modelElement}
        APIUrl={APIUrl}
        token={token}
        elementProperties={elementProperties}
        language={language}
        
        />

        <EditorSelector
        APIUrl={APIUrl}
        token={token}
        modelElement={modelElement}
        elementChildren={elementChildren}
        elementScheme={elementScheme}
        setModelElement={setModelElement}
        language={language}
        root={root}
        setAutoReferenced={setAutoReferenced}
        autoReferenced={autoReferenced}
        />

    </div>
  )
}

export default Model