import { MDBPagination, MDBPaginationItem, MDBPaginationLink } from "mdb-react-ui-kit";
import { getNextPage } from "./APICalls";

let style={
    cursor:'pointer'
}


export function pagionation(APIUrl, token, setModelElement, elementID, relationName, pagecount, currentpage){
    if(pagecount<=1)return;
    return(
        <nav aria-label='Page navigation'>
        <MDBPagination className='mb-0'>
            <MDBPaginationItem >
                <MDBPaginationLink 
                style={style} 
                onClick={()=>{
                    getNextPage(
                        APIUrl, 
                        token, 
                        elementID, 
                        relationName, 
                        0, 
                        10)
                    .then(data=>{
                        setModelElement(prev=>({
                            ...prev,
                            children:{
                                ...prev.children,
                                [relationName]:data
                            }
                        }))
                    })}} id={pagecount+"_"+0} aria-label='Preious'>First</MDBPaginationLink>
            </MDBPaginationItem>
            {Array.from(Array(pagecount), (e, i) => {
            
                if(currentpage !==0&&i === 0){
                    return(
                    <MDBPaginationItem className={i===currentpage?"pagination active":"pagination"} key={i}>
                        <MDBPaginationLink 
                        style={style} 
                        onClick={()=>{
                            getNextPage(
                                APIUrl, 
                                token, 
                                elementID, 
                                relationName, 
                                0, 
                                10)
                            .then(data=>{
                                console.log(relationName,data)
                                setModelElement(prev=>({
                                    ...prev,
                                    children:{
                                        ...prev.children,
                                        [relationName]:data
                                    }
                                }))
                            })}}>{1}</MDBPaginationLink>
                    </MDBPaginationItem>
                    )
                }else if(i === 1 && i < currentpage-1){
                    return(
                        <MDBPaginationItem className={i===currentpage?"pagination active":"pagination"} key={i}>
                            <MDBPaginationLink style={style}>{"..."}</MDBPaginationLink>
                        </MDBPaginationItem>
                    )
                }else if(i > currentpage-3 && i < currentpage){
                    return(
                        <MDBPaginationItem className={i===currentpage?"pagination active":"pagination"} key={i}>
                            <MDBPaginationLink 
                            style={style} 
                            onClick={()=>{
                                getNextPage(
                                    APIUrl, 
                                    token, 
                                    elementID, 
                                    relationName, 
                                    i, 
                                    10)
                                .then(data=>{

                                    setModelElement(prev=>({
                                        ...prev,
                                        children:{
                                            ...prev.children,
                                            [relationName]:data
                                        }
                                    }))
                                })}}
                            >{i+1}</MDBPaginationLink>
                        </MDBPaginationItem>
                    )
                }else if(i < currentpage+3 && i >= currentpage){
                    return(
                        <MDBPaginationItem className={i===currentpage?"pagination active":"pagination"} key={i}>
                            <MDBPaginationLink 
                            style={style} 
                            onClick={()=>{
                                getNextPage(
                                    APIUrl, 
                                    token, 
                                    elementID, 
                                    relationName, 
                                    i, 
                                    10)
                                .then(data=>{
                                    console.log(relationName,data)

                                    setModelElement(prev=>({
                                        ...prev,
                                        children:{
                                            ...prev.children,
                                            [relationName]:data
                                        }
                                    }))
                                })}}
                            >{i+1}</MDBPaginationLink>
                        </MDBPaginationItem>
                    )
                }else if(i !== currentpage && i === pagecount-1){
                    return(
                        <MDBPaginationItem key={i}>
                            <MDBPaginationLink style={style}>{'...'}</MDBPaginationLink>
                        </MDBPaginationItem>
                    )
                }else if(i !== currentpage && i === pagecount-1){
                    return(
                        <MDBPaginationItem className={i===currentpage?"pagination active":"pagination"} key={i}>
                            <MDBPaginationLink 
                            style={style} 
                            onClick={()=>{
                                getNextPage(
                                    APIUrl, 
                                    token, 
                                    elementID, 
                                    relationName, 
                                    i, 
                                    10)
                                .then(data=>{
                                    console.log(relationName,data)

                                    setModelElement(prev=>({
                                        ...prev,
                                        children:{
                                            ...prev.children,
                                            [relationName]: data
                                        }
                                    }))
                                })}}
                            >{i+1}</MDBPaginationLink>
                        </MDBPaginationItem>
                    )
                }
            })}
          <MDBPaginationItem>
            <MDBPaginationLink 
            style={style}
            onClick={()=>{
                getNextPage(
                    APIUrl, 
                    token, 
                    elementID, 
                    relationName, 
                    pagecount-1, 
                    10)
                .then(data=>{
                    setModelElement(prev=>({
                        ...prev,
                        children:{
                            ...prev.children,
                            [relationName]:data
                        }
                    }))
                })}}
            >Last</MDBPaginationLink>
          </MDBPaginationItem>
        </MDBPagination>
      </nav>
    )
}
