import { 
    MDBBtn, 
    MDBCard, 
    MDBCardBody, 
    MDBCardFooter, 
    MDBCardImage, 
    MDBCardText, 
    MDBCardTitle, 
    MDBCol, 
    MDBContainer, 
    MDBIcon, 
    MDBListGroup, 
    MDBListGroupItem, 
    MDBPagination, 
    MDBPaginationItem, 
    MDBPaginationLink, 
    MDBRow, 
    MDBTooltip } from 'mdb-react-ui-kit'

import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SearchBar from '../SubComponents/SearchBar';
import { findElementMultiple } from '../../../functions/APICalls';

let style={
    cursor:'pointer'
}

function ModelSearchView({
  APIUrl,
  token,
  modelElement,
  globalProperties,
  setModelElement,
  language,
  searchedElements,
  setSearchedElements,
  mandantSettings
}) {

    const navigate = useNavigate();
    const {searchName, searchValue} = useParams();

    useEffect(()=>{
        if(searchedElements===undefined)return;
        console.log(searchedElements)
    },[searchedElements])

  return (
    <div className='center-panel-model'>
        <MDBRow className='mx-3 p-0 gx-0 mb-3'>
            
            <SearchBar
            APIUrl={APIUrl}
            token={token}
            setSearchedElements={setSearchedElements}
            mandantSettings={mandantSettings}
            />

        </MDBRow>
        <MDBRow className='mx-3 ps-4 mb-3'>
            <h3>{searchName}: {searchValue}</h3>
        </MDBRow>

        <MDBRow key={"SearchRow"} className='mx-3 p-0 gx-0'>
            {searchName!=="All"
            ?
            <>
            {searchedElements[String(searchName).toLowerCase()].content.map((searchResults)=>{
                return(
                    <>
                    <MDBCard
                    onClick={(e)=>{
                    if(e.target.tagName==='A' || e.target.tagName==='I'){
                        return
                    }else{
                        navigate(`/model/${searchResults.id}`);                        
                    }
                    }}
                    key={String(searchResults.name + " "+ searchResults.id)}
                    className='element-card'>
                    
                    
                    {searchResults.previewImage&&
                    <MDBCardImage src={`${APIUrl}/media/${searchResults.previewImage}?token=${token}&preview=true&scaleLongSideSize=150`} position='top' />
                    }
                    <MDBCardTitle className='p-3'>
                        {searchResults.name!==undefined?searchResults.name:"NAME"}
                    </MDBCardTitle>
                    <MDBCardBody >
                        <MDBListGroup flush light>
                        <MDBListGroupItem>
                            {searchResults.id}
                        </MDBListGroupItem>
                        {searchResults.previewText &&
                        <MDBListGroupItem>

                            <MDBCardText>
                            {searchResults.previewText}
                            </MDBCardText>
                        </MDBListGroupItem>
                        }
                        </MDBListGroup>

                    </MDBCardBody>
                    
                    </MDBCard>
                    </>
                )
            })}
            <MDBRow>
            <MDBPagination className='mb-0' style={{width:"10vw", overflowX:"scroll",height:"50px"}}>
                {Array.from(Array(searchedElements[String(searchName).toLowerCase()].pages), (element, index)=>{
                    return(
                        <MDBPaginationItem className={index===searchedElements[String(searchName).toLowerCase()].currentPage?"pagination active":"pagination"} key={index}>
                            <MDBPaginationLink
                            style={style}
                            onClick={()=>{
                                findElementMultiple(APIUrl, token, String(searchName).toLowerCase(), searchValue, index)
                                .then(data=>{
                                    setSearchedElements(data)
                                })
                            }}>
                                {index+1}
                            </MDBPaginationLink>

                        </MDBPaginationItem>
                    )
                })}

            </MDBPagination>
            </MDBRow>
            </>
            :
            <>
            {Object.keys(searchedElements).map(searchedElementKey=>{
                return(
                    <>
                    <MDBRow className='mb-3 border-bottom'>
                        {searchedElementKey}
                    </MDBRow>
                    <MDBRow className='mb-3'>
                        {searchedElements[searchedElementKey].content!==undefined&&searchedElements[searchedElementKey].content.map(searchResults=>{
                            return(
                                <>
                                    <MDBCard
                                    onClick={(e)=>{
                                    if(e.target.tagName==='A' || e.target.tagName==='I'){
                                        return
                                    }else{
                                        navigate(`/model/${searchResults.id}`);                        
                                    }
                                    }}
                                    key={String(searchResults.name + " "+ searchResults.id)}
                                    className='element-card'>
                                    
                                    
                                    {searchResults.previewImage&&
                                    <MDBCardImage src={`${APIUrl}/media/${searchResults.previewImage}?token=${token}&preview=true&scaleLongSideSize=150`} position='top' />
                                    }
                                    <MDBCardTitle className='p-3'>
                                        {searchResults.name!==undefined?searchResults.name:"NAME"}
                                    </MDBCardTitle>
                                    <MDBCardBody >
                                        <MDBListGroup flush light>
                                        <MDBListGroupItem>
                                            {searchResults.id}
                                        </MDBListGroupItem>
                                        {searchResults.previewText &&
                                        <MDBListGroupItem>
                
                                            <MDBCardText>
                                            {searchResults.previewText}
                                            </MDBCardText>
                                        </MDBListGroupItem>
                                        }
                                        </MDBListGroup>
                
                                    </MDBCardBody>
                                    
                                    </MDBCard>
                                    </>
                            )
                        }   
                        )}
                    </MDBRow>
                    <MDBRow>
                    <MDBPagination className='mb-0' style={{width:"100%", overflowX:"scroll",height:"50px"}}>
                        {Array.from(Array(searchedElements[String(searchedElementKey).toLowerCase()].pages), (element, index)=>{
                            return(
                                <MDBPaginationItem className={index===searchedElements[String(searchedElementKey).toLowerCase()].currentPage?"pagination active":"pagination"} key={index}>
                                    <MDBPaginationLink
                                    style={style}
                                    onClick={()=>{
                                        findElementMultiple(APIUrl, token, String(searchedElementKey).toLowerCase(), searchValue, index)
                                        .then(data=>{
                                            console.log(data)
                                            setSearchedElements(prev=>({...prev,[searchedElementKey]:data[searchedElementKey]}))
                                        })
                                    }}>
                                        {index+1}
                                    </MDBPaginationLink>

                                </MDBPaginationItem>
                            )
                        })}

            </MDBPagination>
            </MDBRow>

                    </>
                )
            })}
            
            </>
        }
        </MDBRow>
    </div>
  )
}

export default ModelSearchView