import { 
  MDBBtn, 
  MDBCard, 
  MDBCardBody, 
  MDBCardFooter,
  MDBCardHeader, 
  MDBCardImage, 
  MDBCardLink, 
  MDBCardSubTitle,
  MDBCardText,
  MDBCardTitle,
  MDBCol, 
  MDBContainer, 
  MDBIcon, 
  MDBListGroup, 
  MDBListGroupItem, 
  MDBPopover, 
  MDBPopoverBody, 
  MDBPopoverHeader, 
  MDBRow, 
  MDBScrollspy, 
  MDBSpinner, 
  MDBTable, 
  MDBTabs, 
  MDBTabsContent, 
  MDBTabsItem, 
  MDBTabsLink, 
  MDBTabsPane,
  MDBTooltip
} from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react'
import { addExistingElementToParent, addNewElement, addNewProperty, alterElement, createNewElement, findRelationTargets, getClassSchema, getElement, getSchemaClassTypes, getSettings, removeElement } from '../../../functions/APICalls';
import './Editors.css';
import languages from "../../../languages/languages.json";
import { useNavigate, useParams } from 'react-router-dom';


function LocalizedLanguageList({
    APIUrl,
    token,
    modelElement,
    schemeChilds,
    setModelElement,
    elementChildren
}) {

  const navigate = useNavigate();
  const {elementID} = useParams();


  const [existingListOriginal, setExistingListOriginal] = useState(undefined);
  const [existingList, setExistingList] = useState(undefined);
  const [searchExisting, setSearchExisting] = useState(undefined);
  const [existingListProgress, setExistingListProgress] = useState(false);


  const [localizedTextLanguages, setLocalizedTextLanguages] = useState([]);
  const [basicActive, setBasicActive] = useState('tab1');

  const [mediaListScheme, setMediaListScheme] = useState();
  const [mediaListSchemeLanguages, setMediaListSchemeLanguages] = useState();

  const [globalLanguages, setGlobalLanguages] = useState();

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };


  function addExistingElement(relationName,id){
    var activeLanguage = globalLanguages[parseInt(basicActive.charAt(basicActive.length-1))-1]

    //console.log(id)
    addExistingElementToParent(APIUrl, token, localizedTextLanguages[activeLanguage].id, id, relationName)
    .then(data=>{
      if(localizedTextLanguages[activeLanguage].children.length==0){
        setLocalizedTextLanguages(prev=>({
          ...prev,
          [activeLanguage]:{
            ...prev[activeLanguage],
            children:[data]}
        }))
      }else{
        setLocalizedTextLanguages(prev=>({
          ...prev,
          [activeLanguage]:{
            ...prev[activeLanguage],
            children:[...prev[activeLanguage].children, data]}
        }))

      }
      
      setExistingList(undefined)
    })


    
  }

  useEffect(()=>{
    if(modelElement===undefined)return;
    let mediaLanguageList = []
    console.log(schemeChilds)
    if(modelElement.children[schemeChilds.relationName]!==undefined){
      modelElement.children[schemeChilds.relationName].content.map((localizedMedia,mediaID)=>{ 
        getElement(APIUrl,token, localizedMedia.id)
        .then(data=>{
          console.log(data)
          getClassSchema(APIUrl,token,data.type)
          .then(schemeClass=>{
            console.log(schemeClass)
            setMediaListScheme(schemeClass)
            schemeClass.childRelations.map(element=>{
              if(element.targetClassName === "I18nText"){
                setMediaListSchemeLanguages(element)
                
              }
            })
          })
          if(data.children !== undefined && 
            data.children.text !==undefined &&
            data.children.text.content !== undefined
            ){
              console.log(data)
              setLocalizedTextLanguages(prev=>({
                ...prev,
                [data.properties.filter(prop=>prop.name==="locale")[0].value]:{
                  id:data.id,
                  children:data.children.text.content,
                  validationResult:data.validationResult

                }
              }))


          }else{
            if(data.properties.filter(prop=>prop.name==="locale")[0]===undefined)return;
            setLocalizedTextLanguages(prev=>({
              ...prev,
              [data.properties.filter(prop=>prop.name==="locale")[0].value]:{
                id:data.id,
                children:[],
                validationResult:data.validationResult
              }
            }))

          }
      })
    })
  }
  getSettings(APIUrl, token)
  .then(settings=>{
    let languages = settings.settings.project.filter(set=>set.key==="default.i18n.languages")[0].value.split(',')
    setGlobalLanguages(languages)
  })
  },[elementID])


  function removeItem(relationName,parentID, id){
    
    removeElement(APIUrl, token, parentID , relationName, id)
    .then(res=>{
      var activeLanguage = globalLanguages[parseInt(basicActive.charAt(basicActive.length-1))-1]
      let newItems = localizedTextLanguages[activeLanguage].children.filter(elements=>elements.id!==id)
      setLocalizedTextLanguages(prev=>({
        ...prev,
        [activeLanguage]:{
          ...prev[activeLanguage],
          children:newItems
        }
      }))
    })
  }


  function addLanguageToElement(language){
    
    addNewElement(APIUrl, token, elementID, "label")
    .then(newElement=>{
      getClassSchema(APIUrl, token, newElement.type).then(newSchema=>{
        setMediaListScheme(newSchema)
          newSchema.childRelations.map(element=>{
          if(element.targetClassName === "I18nText"){
            setMediaListSchemeLanguages(element)
          }
        })
        addNewProperty(APIUrl, token, "locale", newElement.id, language)
        .then(prop=>{
          setLocalizedTextLanguages(prev=>({
            ...prev,
            [language]:{
              id:newElement.id,
              children:[]
            }
          }))
        })

      })
      
      
    })

  }

  function selectFromExisting(relationName, localeID){
    setExistingListProgress(true);
    if(existingList!=undefined){
      setExistingList(undefined);
    setExistingListProgress(false);

    }else{
      setExistingList(undefined);
      setExistingListOriginal(undefined);

      findRelationTargets(APIUrl, token, localeID, relationName)
      .then(data=>{
        
        setExistingList(prev=>({[relationName]:data}));
        setExistingListOriginal(prev=>({[relationName]:data}));
        setSearchExisting(prev=>(undefined))
        setExistingListProgress(false);
      })
    }

  }

  function addElement(relationName, id, language){
    
    createNewElement(APIUrl, token, "newText", "I18nText")
    .then(res=>{
      addExistingElementToParent(APIUrl, token, localizedTextLanguages[language].id, res.id, relationName)
      .then(data=>{
        setLocalizedTextLanguages(prev=>({
          ...prev,
          [language]:{
            ...prev[language],
            children:[...prev[language].children, data]
          }
        }))
      })
    })
  }

  // useEffect(()=>{
  //   if(localizedTextLanguages===undefined)return;
  //   console.log(localizedTextLanguages)
  // },[localizedTextLanguages])


  return (
    <MDBRow className='border my-3'>
        <MDBRow  className='mt-3 p-0 ps-3'>
        <h5 >{String(schemeChilds.relationName).toUpperCase()}</h5>
        <hr  style={{width:"80%", marginLeft:"1%"}}/>
        </MDBRow>
        <MDBRow key={"R2"} className='ms-3'>
        {(localizedTextLanguages!==undefined&&mediaListSchemeLanguages!==undefined)
        ?<>
        <MDBTabs className='mb-3' justify >
          {Object.keys(localizedTextLanguages).map((language,languageID)=>{
            return(
                <MDBTabsItem>
                                 
                  <MDBTabsLink onClick={() => handleBasicClick('tab'+(languageID+1))} active={basicActive === 'tab'+(languageID+1)}>
                    <>
                    {language}
                    </>
                  </MDBTabsLink>
                  
                </MDBTabsItem>
            )
          })}
          {Object.keys(localizedTextLanguages).length<mediaListSchemeLanguages.targetMultiplicity.countMax&&
            <MDBTabsItem>
              <MDBTabsLink style={{backgroundColor:"rgba(181, 242, 162, 0.482)"}} onClick={() => handleBasicClick('tab_add')} active={basicActive === 'tab_add'}>
              {languages.de.addLanguage.toUpperCase()} <MDBIcon className='ps-1' fas icon='fa-solid fa-plus'/>
                
              </MDBTabsLink>
            </MDBTabsItem>
          } 

        </MDBTabs>
        <MDBTabsContent>
        {Object.keys(localizedTextLanguages).map((language,languageID)=>{
          // console.log(mediaListSchemeLanguages)
            return(
                <MDBTabsPane show={basicActive===('tab'+(languageID+1))}>
                  
                  <MDBRow >
                    <MDBCol className='p-3'>
                      <MDBRow >

                        <MDBCol md={2}>
                        <h6>Text Elements</h6>
                        </MDBCol>
                        <MDBCol md={1}>
                          {(localizedTextLanguages[language].validationResult!==undefined&&
                            localizedTextLanguages[language].validationResult.errorsMap!==undefined&&
                            localizedTextLanguages[language].validationResult.errorsMap.ChildMissingError!==undefined
                          )&&
                          localizedTextLanguages[language].validationResult.errorsMap.ChildMissingError.map(missingMedia=>{
                            return(
                              <MDBPopover dismiss color='none' tag={'a'} btnChildren={
                                <MDBIcon fas icon='fa-thin fa-circle-exclamation' style={{color:"lightcoral"}}/>
                              }>
                                <MDBPopoverHeader>
                                  
                                  {"ERROR: "+String(missingMedia.errorType).toUpperCase()}
                                </MDBPopoverHeader>
                                <MDBPopoverBody>
                                  {missingMedia.info.localizedTexts.filter(text=>text.locale===language).length>0&&missingMedia.info.localizedTexts.filter(text=>text.locale===language)[0].text}
            
                                </MDBPopoverBody>
                              </MDBPopover>

                            )
                          })
                          
                          }

                        </MDBCol>
                        <MDBCol md={1}>
                            <MDBBtn size='md' tag={'a'} color='none' onClick={()=>{
                              // removeItem(schemeChilds.relationName,localizedMediaLanguages[language].id, languageMediaContent.id)
                            console.log(modelElement.children[schemeChilds.relationName].content)
                            //Iterate all medialists and remove all of them
                            modelElement.children[schemeChilds.relationName].content.forEach(list=>{
                              console.log(list);
                              removeItem(schemeChilds.relationName, modelElement.id, list.id)
                            });
                              // removeItem(schemeChilds.relationName, modelElement.id, localizedMediaLanguages[language].id)
                            }}>
                              <MDBTooltip tag={'a'} title="Remove all Media Elements">
                                <MDBIcon fas icon='fa-light fa-remove'/>
                              </MDBTooltip>
                            </MDBBtn>
                            

                        </MDBCol>
                        <hr key={"R1_divider"} style={{width:"100%", marginLeft:"1%"}}/>

                        {localizedTextLanguages[language].children.map((languageMediaContent,languageMediaContentID)=>{
                            return(
                              <MDBCard
                              key={languageMediaContentID+"_languageMediaContent_"+language}
                              className="element-card"
                              onClick={(e)=>{
                                if(e.target.tagName==='A'|| e.target.tagName==='I'){
                                  return
                                }
                                navigate(`/model/${languageMediaContent.id}`);                        
                              }}
                              >
                                {languageMediaContent.previewImage&&
                                  <MDBCardImage src={`${APIUrl}/media/${languageMediaContent.previewImage}?token=${token}&preview=true&scaleLongSideSize=150`} position='top' />
                                }
                                <MDBCardTitle className=''>
                                  {languageMediaContent.name!==undefined?languageMediaContent.name:"NAME"}
                                </MDBCardTitle>
                                <MDBCardBody >
                                <MDBListGroup flush light>
                                  
                                  <MDBListGroupItem>
                                      {languageMediaContent.id}
                                    </MDBListGroupItem>
                                  {languageMediaContent.previewText &&
                                    <MDBListGroupItem>
                                      <MDBTooltip placement='bottom' tag='a' title={languageMediaContent.previewText}>
                                        <MDBCardText style={{maxHeight:"50px", overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}}>
                                          {languageMediaContent.previewText}
                                        </MDBCardText>
                                      </MDBTooltip>
                                      
                                    </MDBListGroupItem>
                                  }
                                  </MDBListGroup>
                                  
                                </MDBCardBody>
                                <MDBCardFooter style={{height:"30%"}} className='d-flex align-items-center justify-content-center'>
                                {!schemeChilds.autoReferenceAllTargetClassInstances&&
                                <>
                                  <MDBBtn size='sm' style={{position:"absolute", bottom:"0px",right:"0px", color:"lightcoral"}} tag={'a'} color='none' onClick={()=>{
                                    removeItem("text",localizedTextLanguages[language].id, languageMediaContent.id)
                                  }}>
                                    <MDBTooltip tag={'a'} title="Remove this item">
                                      <MDBIcon fas icon='fa-light fa-link-slash'/>
                                    </MDBTooltip>
                                  </MDBBtn>
                                </>
                                

                                }
                                </MDBCardFooter>

                              </MDBCard>

                            )
                          })
                        }

                        {mediaListSchemeLanguages!==undefined&&
                        <>
                        {mediaListSchemeLanguages.targetMultiplicity.countMax>localizedTextLanguages[language].children.length&&
                        <>
                          <MDBCard
                          onClick={()=>{
                            console.log("FIRST");
                            addElement("text", localizedTextLanguages[language].id, language);
                                      
                          }}
                          key={"media_addSymbol"}
                          className='element-card add-card'>
                            <MDBCardHeader>
                              {languages.de.addNewElement}

                            </MDBCardHeader>
                            <MDBCardBody style={{height:"20%"}} className='d-flex align-items-center justify-content-center'>
                              <MDBIcon fas icon='fa-light fa-plus'/>
                            </MDBCardBody>

                          </MDBCard>
                          <MDBCard
                          onClick={()=>{
                            selectFromExisting("media", localizedTextLanguages[language].id)       
                          }}
                          key={"media_select_addSymbol"}
                          className='element-card add-card'>
                            <MDBCardHeader>
                              {languages.de.selectExistingElement}

                            </MDBCardHeader>
                            <MDBCardBody style={{height:"20%"}} className='d-flex align-items-center justify-content-center'>
                              <MDBIcon fas icon='fa-light fa-list'/>
                            </MDBCardBody>


                          </MDBCard>
                        </>
                        }
                        </>
                        }
                      </MDBRow>
                    </MDBCol>

                    {(existingList!==undefined && existingList[schemeChilds.relationName]!==undefined) &&
                      <MDBCol className='mb-3 select-table'>
                        <MDBRow className='mb-3'>
                          <MDBCol md="8">
                            
                            
                          </MDBCol>
                          <MDBCol md='2'>
                            
                          </MDBCol>
                          <MDBCol md='2'>
                            
                          </MDBCol>
                        </MDBRow>
                        <MDBRow style={{height:"30vh", overflowY:"scroll"}}>
                          <MDBTable striped bordered hover size="sm" >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>ID</th>
                                <th>Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {existingList[schemeChilds.relationName].map((selectionPossibilities, possibilitieId)=>{
                                return(
                                  <tr 
                                  className={possibilitieId%2!==0?"grey-table-col":"white-table-col"} 
                                  
                                  onMouseEnter={(e)=>{
                                    
                                    e.target.parentElement.childNodes.forEach(childNode=>(childNode.classList.add("hovered")));
                                  }}
                                  onMouseLeave={(e)=>(e.target.parentElement.childNodes.forEach(childNode=>(childNode.classList.remove("hovered"))))}
                                  onClick={()=>(addExistingElement(schemeChilds.relationName, selectionPossibilities.id))}
                                  >
                                    <td>
                                      {possibilitieId+1}
                                    </td>
                                    <td>
                                      {selectionPossibilities.id}
                                    </td>
                                    <td>
                                      {selectionPossibilities.name}
                                    </td>
                                      
                                  </tr>
                                )
                              })}
                            </tbody>
                            </MDBTable>
                          </MDBRow>
                      </MDBCol>
                    }   
                  </MDBRow>

                </MDBTabsPane>
            )
          })}
          <MDBTabsPane show={basicActive===('tab_add')}>
          <MDBRow>
              {(globalLanguages!==undefined)&&
              <>
              {globalLanguages.map((lang,langID)=>{
                if(Object.keys(localizedTextLanguages).includes(lang))return;
                
                return(
                  <MDBCard
                  className='element-card add-card'
                  key={lang+langID}
                  onClick={()=>{
                    addLanguageToElement(lang)
                  }}
                  >
                    <MDBCardHeader>
                      <MDBCardTitle>
                        {lang.toUpperCase()}
                      </MDBCardTitle>
                    </MDBCardHeader>
                  </MDBCard>
                )
              })}
              
              </>}
            </MDBRow>
          </MDBTabsPane>
        </MDBTabsContent>

        </>
        :
        <>
        <MDBTabs className='mb-3' justify>
          <MDBTabsItem>
            <MDBTabsLink style={{backgroundColor:"rgba(181, 242, 162, 0.482)"}} onClick={() => handleBasicClick('tab_add')} active={basicActive === 'tab_add'}>
              {languages.de.addLanguage.toUpperCase()} <MDBIcon className='ps-1' fas icon='fa-solid fa-plus'/>
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

          <MDBTabsContent>
          <MDBTabsPane show={basicActive===('tab_add')}>
            <MDBRow>
              {(globalLanguages!==undefined)&&
              <>
              {globalLanguages.map((lang,langID)=>{
                return(
                  <MDBCard
                  className='element-card add-card'
                  key={lang+langID}
                  onClick={()=>{
                    addLanguageToElement(lang)
                  }}
                  >
                    <MDBCardHeader>
                      <MDBCardTitle>
                        {lang.toUpperCase()}
                      </MDBCardTitle>
                    </MDBCardHeader>
                  </MDBCard>
                )
              })}
              
              </>}
            </MDBRow>

          </MDBTabsPane>
          </MDBTabsContent>
          </>
        }
        </MDBRow>
    </MDBRow>
  )
}

export default LocalizedLanguageList