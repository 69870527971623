import React, {useState, useRef, useEffect}  from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import accesstoken from './accesstoken';
import { MDBBtn, MDBContainer, MDBIcon, MDBInputGroup, MDBRow, MDBSpinner, MDBTooltip } from 'mdb-react-ui-kit';
import { addNewProperty, alterPropertyWithoutName } from '../../../functions/APICalls';


function MapEditor({
  APIUrl,
  token,
  modelElement,
  elementChildren
}) {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoom, setZoom] = useState(9);

  const marker = useRef(new mapboxgl.Marker());
  const [initialCoord, setInitialCoord] = useState(false)

  useEffect(() => {
    if (map.current) return; // initialize map only once
    var coordinates = modelElement.properties.find(element=>{
        return element.name === "coordinates"
    })

    if(coordinates!==undefined){
      coordinates = coordinates.value.split('|')
      if(isNaN(coordinates[0] )|| isNaN(coordinates[1])){
        setLng(10)
        setLat(49);

      }else{
        setLat(coordinates[1]);
        setLng(coordinates[0]);
      }

    }else{
      setLat(49);
      setLng(10);
    }
    
    setInitialCoord(true);
  });

  useEffect(()=>{
    map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [lng, lat],
        zoom: zoom
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    marker.current.setLngLat([lng, lat]).addTo(map.current);

    map.current.on('mousedown', (e)=>{
        if(e.originalEvent.button == 2){
            marker.current.setLngLat([e.lngLat.lng, e.lngLat.lat]);
            setLat(prev=>e.lngLat.lat);
            setLng(prev=>e.lngLat.lng);
        }
        
    })
  },[initialCoord])

  useEffect(()=>{
    if(!marker.current)return
    if(isNaN(lng)){
      marker.current.setLngLat([0.00,lat]);
    }else if(isNaN(lat)){
      marker.current.setLngLat([lng,0.00]);
    }else{
      marker.current.setLngLat([lng,lat]);

    }
  },[lat,lng])

  return (
    <MDBContainer fluid>
      <MDBRow className='mb-3' >
      Coordinates
        <MDBInputGroup
        noWrapclassName="mb-3 shadow-0"
        textAfter={
          <>
          <MDBBtn 
          className="mx-1 shadow-0" 
          style={{backgroundColor:"lightcoral"}} 
          >
              <MDBIcon fa icon='fa-regular fa-xmark'/>
          </MDBBtn>
          <MDBBtn 
          className={'shadow-0'} 
          style={{backgroundColor:"#91c78b"}}
          onClick={(e)=>{
            let value = `${lng}%7c${lat}`
            let coordID = modelElement.properties.filter(prop=>prop.name==="coordinates");
            if(coordID[0]!==undefined){
              alterPropertyWithoutName(APIUrl,token,coordID[0].id, value )
            }else{
              addNewProperty(APIUrl,token, 'coordinates', modelElement.id, value)
              .then(data=>{
                //console.log(data)
              })
            }
            }}
          >
              <MDBIcon fa icon='fa-light fa-check'/>
          </MDBBtn>
          
          </>
        }>
          
          <input label={"Latitude"} className='form-control' type="number" step="0.001" value={lat} onChange={(e)=>setLat(parseFloat(e.target.value))}/>
          <input label={"Longitude"} className='form-control' type="number" step="0.001" value={lng} onChange={(e)=>setLng(parseFloat(e.target.value))}/>


        </MDBInputGroup>

      </MDBRow>
      <MDBRow className='d-flex justify-content-center' style={{height:"50vh"}}>
        {mapContainer.current!==undefined
          ?<>

            <div ref={mapContainer} className="map-container" style={{height:"100%", position:"relative", width:"90%"}}/>
            <MDBTooltip tag={'a'} title='Right mouse click to select position in Map'>
              <MDBIcon className='mb-3' fas icon='fa-solid fa-info' style={{position:"relative", top:"0px", left:"50%"}}/>
            </MDBTooltip>
          </>
          :<MDBSpinner role='status' key={"Spinner"}>
            <span className='visually-hidden'>Loading...</span>
          </MDBSpinner>
        }
        
      </MDBRow>

    </MDBContainer>
  )
}

export default MapEditor