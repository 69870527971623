import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Footer({
  APIUrl,
  token,
  modelElement,
  setModelElement,
  

}) {

  const navigate = useNavigate()

  useEffect(()=>{
    if(modelElement===undefined)return;
    // console.log(modelElement)
  },[modelElement])


  return (
    <div style={{position:"absolute", right:"0px"}}>
      <MDBBtn tag={'a'} color='none' onClick={()=>(navigate('/impress'))} className='mx-3' style={{color:"black"}}>
        Impress
      </MDBBtn>

    </div>
  )
}

export default Footer