import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardImage, MDBCardLink, MDBCardSubTitle, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBInputGroup, MDBListGroup, MDBListGroupItem, MDBModal, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBPopover, MDBPopoverBody, MDBPopoverHeader, MDBRow, MDBScrollspy, MDBSpinner, MDBTable, MDBTooltip } from 'mdb-react-ui-kit'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { addNewElement, findRelationTargets, createNewElement, getElement, addExistingElementToParent, removeElement, deleteElement, getClassSchema } from '../../../functions/APICalls';
import { pagionation } from '../../../functions/pagination';

import './Editors.css';
import languages from "../../../languages/languages.json";

import LocalizedLanguageList from './LocalizedLanguageList';
import LocalizedMediaLanguageList from './LocalizedMediaLanguageList';

function ChildEditor({
  APIUrl,
  token,
  modelElement,
  elementChildren,
  setModelElement,
  language,
  root,
  autoReferenced, 
  setAutoReferenced
}) {

  const navigate = useNavigate();
  const {elementID} = useParams();
  const [hover, setHover] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const onHover = (elem) => {
    setHover(prev=>({...prev,[elem]:true}));
  };

  const onLeave = (elem) => {
    setHover(prev=>({...prev,[elem]:false}));
  };

  //set the localized editors
  useEffect(()=>{
    if(modelElement===undefined)return;
      console.log(modelElement)
      getClassSchema(APIUrl, token, modelElement.type).then(data=>console.log(data))
      //console.log(elementChildren)


  },[elementID]);

  //function for adding a new element
  function addElement(relationName, id){
    addNewElement(APIUrl,token,elementID, relationName)
    .then(data=>{
      if(modelElement.children[relationName]===undefined){
        setModelElement(prev=>({
          ...prev,
          children:{
            ...prev.children,
            [relationName]:{
              content:[
                data
              ]
            }
          }
  
        }))

      }else{
        setModelElement(prev=>({
          ...prev,
          children:{
            ...prev.children,
            [relationName]:{
              ...prev.children[relationName],
              content:[
                ...prev.children[relationName].content,
                data
              ]
            }
          }
  
        }))
      }
      
      onLeave(id)
    })
  }

  function addExistingElement(relationName,id){ 
    addExistingElementToParent(APIUrl, token, elementID, id, relationName)
    .then(data=>{
      if(modelElement.children===undefined || modelElement.children[relationName] === undefined){
        setModelElement(prev=>({
          ...prev,
          children:{
            [relationName]:{
              content:[
                data
              ]
            }
          }
    
        }))
      }else{
        setModelElement(prev=>({
          ...prev,
          children:{
            ...prev.children,
            [relationName]:{
              ...prev.children[relationName],
              content:[
                ...prev.children[relationName].content,
                data
              ]
            }
          }
        }))
      }
      setExistingList(undefined);
      onLeave(id);
    })


    
  }

  //function for creating a completely new element
  function createElement(relationName){
    addElement(relationName, null)
  }


  //Function for the add-card select from existing
  const [existingListOriginal, setExistingListOriginal] = useState(undefined);
  const [existingList, setExistingList] = useState(undefined);
  const [searchExisting, setSearchExisting] = useState(undefined);
  const [existingListProgress, setExistingListProgress] = useState(false);


  function selectFromExisting(relationName, id){
    setExistingListProgress(true);
    if(existingList!=undefined){
      setExistingList(undefined)
      setExistingListProgress(false);

    }else{
      setExistingList(undefined);
      setExistingListOriginal(undefined);

      findRelationTargets(APIUrl, token, elementID, relationName)
      .then(data=>{
        var list = data.sort((a,b)=>(a.name.toUpperCase()<b.name.toUpperCase())?-1:(b.name.toUpperCase()<a.name.toUpperCase())?1:0)
        setExistingList(prev=>({[relationName]:list}));
        setExistingListOriginal(prev=>({[relationName]:list}));
        setSearchExisting(prev=>(undefined));
        setExistingListProgress(false);
        onLeave(id);
      })
    }
    

  }

  function addElementCard(id, relationName, autoreferenced){
    return(
      <>
      {hover[id]?
      <>
      <MDBCard
       onClick={()=>{
        createElement(relationName);
      }}
      key={relationName+"_addSymbol"}
      className='element-card add-card'>
        <MDBCardHeader>
          {languages.de.addNewElement}

        </MDBCardHeader>
        <MDBCardBody style={{height:"20%"}} className='d-flex align-items-center justify-content-center'>
          <MDBIcon fas icon='fa-light fa-circle-plus'/>
        </MDBCardBody>

      </MDBCard>
      {!autoreferenced&&
      <MDBCard
      onClick={()=>{
        selectFromExisting(relationName, id);       
      }}
      key={relationName+"_addSymbol"}
      className='element-card add-card'>
        <MDBCardHeader>
          {languages.de.selectExistingElement}
        </MDBCardHeader>
        <MDBCardBody style={{height:"20%"}} className='d-flex align-items-center justify-content-center'>
          {existingListProgress
          ?<MDBSpinner><span className='visually-hidden'>Loading...</span></MDBSpinner>
          :<MDBIcon fas icon='fa-light fa-list'/>
          }
        </MDBCardBody>
      </MDBCard>
      }
      </>
      :<MDBCard
        onClick={()=>{
          onHover(id)
        }}
        key={relationName+"_addSymbol"}
        className='element-card add-card'>
          <MDBCardHeader>
            {languages.de.addElement}
          </MDBCardHeader>
          <MDBCardBody style={{height:"20%"}} className='d-flex align-items-center justify-content-center'>
            <MDBIcon fas icon='fa-light fa-plus'/>
          </MDBCardBody>
        </MDBCard>
      }
      </>
    )

  }

  //remove element function
  function removeItem(relationName, id){
    console.log("REMOVE")
    removeElement(APIUrl, token, elementID, relationName, id)
    .then(res=>{
      let newItems = modelElement.children[relationName].content.filter(elements=>elements.id!==id)
      setModelElement(prev=>({
        ...prev,
        children:{
          ...prev.children,
          [relationName]:{
            ...prev.children[relationName],
            content:newItems
          }
        }

      }))
    })

  }

  //deleting the element permanently
  function deleteElementButton(elID){
    console.log(elID)
    deleteElement(APIUrl, token, elID, true)
    .then(deleteRes=>{
      navigate(-1)
    })

  }

  useEffect(()=>{
    if(existingList===undefined)return;
    //console.log(existingList)
  },[existingList])

  return (
    <MDBContainer fluid='true' className='border ' style={{padding:"2% 2% 2% 2%"}}>
      <MDBAccordion flush initialActive={10}>

        {(modelElement!==undefined && elementChildren!==undefined)&&
        elementChildren.map((schemeChilds,schemeChildID)=>{     
          if(modelElement.children[schemeChilds.relationName]===undefined){
            if(schemeChilds.targetClassName==="LocalizedMediaList"){
              return(
                <MDBAccordionItem collapseId={schemeChildID} headerTitle={String(schemeChilds.relationName).toUpperCase()}>
              <LocalizedMediaLanguageList
              APIUrl={APIUrl}
              token={token}
              modelElement={modelElement}
              schemeChilds={schemeChilds}
              setModelElement={setModelElement}
              elementChildren={elementChildren}
              />
              </MDBAccordionItem>
              )
            }else if(schemeChilds.targetClassName==="LocalizedText"){
              return(
                <MDBAccordionItem collapseId={schemeChildID} headerTitle={String(schemeChilds.relationName).toUpperCase()}>
              <LocalizedLanguageList
              APIUrl={APIUrl}
              token={token}
              modelElement={modelElement}
              schemeChilds={schemeChilds}
              setModelElement={setModelElement}
              elementChildren={elementChildren}
              />
              </MDBAccordionItem>
              )
              
              
            }else{
              return(
                <>
                <MDBAccordionItem collapseId={schemeChildID} headerTitle={String(schemeChilds.relationName).toUpperCase()}>
                  <MDBRow className='border my-3'>
                    <MDBCol key={"R0"+schemeChildID} >
                      <MDBRow key={"R1"+schemeChildID} className='my-3 p-0 ps-3'>
                        <MDBCol md={11}>
                        <h5 key={"R1"+schemeChildID+"Header"}>
                          {String(schemeChilds.relationName).toUpperCase()}
                          
                        </h5>
                        </MDBCol>
                        <MDBCol >
                        {(modelElement.validationResult!==undefined&&
                            modelElement.validationResult.errorsMap.ChildMissingError!==undefined)&&
                            modelElement.validationResult.errorsMap.ChildMissingError.map(validationError=>{
                              if(validationError.relation === schemeChilds.relationName){
                                return(
                                  <MDBPopover dismiss color='none' tag={'a'} btnChildren={
                                    <MDBIcon fas icon='fa-thin fa-circle-exclamation' style={{color:"lightcoral"}}/>
                                  }>
                                    <MDBPopoverHeader>
                                      
                                      {"ERROR: "+String(validationError.relation).toUpperCase()}
                                    </MDBPopoverHeader>
                                    <MDBPopoverBody>
                                      {validationError.info.localizedTexts.filter(text=>text.locale===language)[0].text}
                                    </MDBPopoverBody>
                                  </MDBPopover>
                                )
                              }
                            })
                          }
                        </MDBCol>
                        


                        <hr key={"R1"+schemeChildID+"divider"} style={{width:"80%", marginLeft:"1%"}}/>
                      </MDBRow>
                      <MDBRow key={"R2"+schemeChildID} className='mx-3 p0 ps-3'>
                        {addElementCard(schemeChildID, schemeChilds.relationName, schemeChilds.autoReferenceAllTargetClassInstances)}
                      </MDBRow>
                    </MDBCol>
                    {(existingList!==undefined && existingList[schemeChilds.relationName]!==undefined) &&
                      <MDBCol className='mb-3 select-table border-start'>
                        <MDBRow className='m-3'>
                          <MDBCol md="8" >
                            <MDBInputGroup
                            textAfter={
                              <>
                              <MDBCol md={"6"} className='me-2'>
                              <MDBBtn color='none' tag={'a'} 
                              onClick={()=>{
                                var filteredList = existingListOriginal[schemeChilds.relationName].filter((el)=>{if(el.name.includes(searchExisting)){return(el)}})
                                setExistingList(prev=>({[schemeChilds.relationName]:filteredList}))
                              }}>
                                <MDBIcon fas icon='fa-light fa-magnifying-glass'/>
                              </MDBBtn>
                              </MDBCol>
                              <MDBCol md={"6"} className='me-2'>
                              <MDBBtn color='none' tag={'a'} 
                              onClick={()=>{
                                setExistingList(prev=>({[schemeChilds.relationName]:existingListOriginal[schemeChilds.relationName]}))
                              }}>
                                <MDBIcon fas icon='fa-light fa-arrow-rotate-left'/>
                              </MDBBtn>

                              </MDBCol>


                              </>
                            }
                            >
                              <MDBInput type='text' label="search" value={searchExisting} onChange={(e)=>setSearchExisting(e.target.value)}/>

                            </MDBInputGroup>
                            
                            
                          </MDBCol>
                        </MDBRow>
                        <MDBRow style={{height:"30vh", overflowY:"scroll", paddingLeft:"10px"}}>
                          <MDBTable striped bordered hover size="sm" >
                            <thead>
                              <tr>
                                <th>id</th>
                                <th style={{width:"80%"}}>Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {existingList[schemeChilds.relationName].map((selectionPossibilities, possibilitieId)=>{
                                return(
                                  <tr 
                                  className={possibilitieId%2!==0?"grey-table-col":"white-table-col"} 
                                  
                                  onMouseEnter={(e)=>{
                                    
                                    e.target.parentElement.childNodes.forEach(childNode=>(childNode.classList.add("hovered")));
                                  }}
                                  onMouseLeave={(e)=>(e.target.parentElement.childNodes.forEach(childNode=>(childNode.classList.remove("hovered"))))}
                                  onClick={()=>(addExistingElement(schemeChilds.relationName, selectionPossibilities.id))}
                                  >
                                    <td>
                                      {selectionPossibilities.id}
                                    </td>
                                    <td>
                                      {selectionPossibilities.name}
                                    </td>
                                      
                                  </tr>
                                )
                              })}
                            </tbody>
                            </MDBTable>
                          </MDBRow>
                      </MDBCol>
                    }
                  </MDBRow>
                </MDBAccordionItem>
                </>
              )
            }
            
          }
          if(schemeChilds.targetClassName==="LocalizedMediaList"){
            return(
              <MDBAccordionItem collapseId={schemeChildID} headerTitle={String(schemeChilds.relationName).toUpperCase()}>
              <LocalizedMediaLanguageList
              APIUrl={APIUrl}
              token={token}
              modelElement={modelElement}
              schemeChilds={schemeChilds}
              setModelElement={setModelElement}
              elementChildren={elementChildren}
              />
              </MDBAccordionItem>
            )
          }else if(schemeChilds.targetClassName==="LocalizedText" || schemeChilds.targetClassName==="LocalizedTextList"){
            return(
              <MDBAccordionItem collapseId={schemeChildID} headerTitle={String(schemeChilds.relationName).toUpperCase()}>
              <LocalizedLanguageList
              APIUrl={APIUrl}
              token={token}
              modelElement={modelElement}
              schemeChilds={schemeChilds}
              setModelElement={setModelElement}
              elementChildren={elementChildren}
              />
              </MDBAccordionItem>
            )
          }else{
            //console.log(schemeChildID)
            return(
              <MDBAccordionItem collapseId={schemeChildID} headerTitle={String(schemeChilds.relationName).toUpperCase()}>
                <MDBRow key={"R0"+schemeChildID} className='border my-3'>
                  <MDBCol>
                  <MDBRow key={"R1"+schemeChildID} className='my-3 p-0 ps-3'>
                    <h5 key={"R1"+schemeChildID+"Header"}>{String(schemeChilds.relationName).toUpperCase()}</h5>
                    <hr key={"R1"+schemeChildID+"divider"} style={{width:"80%", marginLeft:"1%"}}/>
                  </MDBRow>
                  <MDBRow key={"R2"+schemeChildID} className='mx-3 p-0 gx-0'>
                    {modelElement.children[schemeChilds.relationName]!==undefined&&
                    <>
                    {modelElement.children[schemeChilds.relationName].content.map((elementChild,elementChildID)=>{
                      return(
                        <MDBCard
                        onClick={(e)=>{
                          if(e.target.tagName==='A' || e.target.tagName==='I'){
                            return
                          }else{
                            setAutoReferenced(schemeChilds.autoReferenceAllTargetClassInstances);
                            navigate(`/model/${elementChild.id}`);                        
                          }
                        }}
                        key={String(elementChild.name + " "+ elementChildID)}
                        className='element-card'>
                          
                          
                          {elementChild.previewImage!==undefined&&
                          <MDBCardImage src={`${APIUrl}/media/${elementChild.previewImage}?token=${token}&preview=true&scaleLongSideSize=150`} position='top' />
                          }
                          <MDBCardTitle className='p-3'>
                            {elementChild.name!==undefined?elementChild.name:"NAME"}
                          </MDBCardTitle>
                          <MDBCardBody >
                            <MDBListGroup flush light>
                              <MDBListGroupItem>
                                {elementChild.id}
                              </MDBListGroupItem>
                            {elementChild.previewText &&
                              <MDBListGroupItem>
                                <MDBTooltip placement='bottom' tag='a' title={elementChild.previewText}>
                                  <MDBCardText style={{maxHeight:"50px", overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}}>
                                    {elementChild.previewText}
                                  </MDBCardText>
                                </MDBTooltip>
                                
                              </MDBListGroupItem>
                            }
                            </MDBListGroup>

                          </MDBCardBody>
                          <MDBCardFooter
                          
                          className='d-flex align-items-center justify-content-center'>
                            {!schemeChilds.autoReferenceAllTargetClassInstances&&
                            <>
                              <MDBBtn size='sm' style={{position:"absolute", bottom:"0px",right:"0px", color:"lightcoral"}} tag={'a'} color='none' onClick={()=>{
                                // toggleModal()
                                removeItem(schemeChilds.relationName, elementChild.id)
                              }}>
                                <MDBTooltip tag={'a'} title="Remove this item">
                                  <MDBIcon fas icon='fa-light fa-link-slash'/>
                                </MDBTooltip>
                              </MDBBtn>
                            </>
                            }
                          </MDBCardFooter>
                        </MDBCard>
                        )
                      })
                    }
                    {(schemeChilds.targetMultiplicity.countMax>modelElement.children[schemeChilds.relationName].content.length)&&
                      addElementCard(schemeChildID, schemeChilds.relationName, schemeChilds.autoReferenceAllTargetClassInstances)
                    }
                    </>
                    }
                  </MDBRow>
                  </MDBCol>
                  {(existingList!==undefined && existingList[schemeChilds.relationName]!==undefined) &&
                    <MDBCol className='mb-3 select-table'>
                      <MDBRow className='mb-3'>
                        <MDBCol md="8">
                        <MDBInputGroup
                          textAfter={
                            <>
                            <MDBCol md={"6"} className='me-2'>
                            <MDBBtn color='none' tag={'a'} 
                            onClick={()=>{
                              var filteredList = existingListOriginal[schemeChilds.relationName].filter((el)=>{if(el.name.includes(searchExisting)){return(el)}})
                              setExistingList(prev=>({[schemeChilds.relationName]:filteredList}))
                            }}>
                              <MDBIcon fas icon='fa-light fa-magnifying-glass'/>
                            </MDBBtn>
                            </MDBCol>
                            <MDBCol md={"6"} className='me-2'>
                            <MDBBtn color='none' tag={'a'} 
                            onClick={()=>{
                              setExistingList(prev=>({[schemeChilds.relationName]:existingListOriginal[schemeChilds.relationName]}))
                            }}>
                              <MDBIcon fas icon='fa-light fa-arrow-rotate-left'/>
                            </MDBBtn>

                            </MDBCol>


                            </>
                          }
                          >
                            <MDBInput type='text' label="search" value={searchExisting} onChange={(e)=>setSearchExisting(e.target.value)}/>

                          </MDBInputGroup>
                          
                        </MDBCol>
                      </MDBRow>
                      <MDBRow style={{height:"30vh", overflowY:"scroll"}}>
                        <MDBTable striped bordered hover size="sm" >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>ID</th>
                              <th>Name</th>
                              {(language.data!==undefined&&language.data.previewImage!==undefined)&&<th>{language.data.previewImage}</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {existingList[schemeChilds.relationName].map((selectionPossibilities, possibilitieId)=>{
                              
                              return(
                                <tr 
                                className={possibilitieId%2!==0?"grey-table-col":"white-table-col"} 
                                
                                onMouseEnter={(e)=>{
                                  
                                  e.target.parentElement.childNodes.forEach(childNode=>(childNode.classList.add("hovered")));
                                }}
                                onMouseLeave={(e)=>(e.target.parentElement.childNodes.forEach(childNode=>(childNode.classList.remove("hovered"))))}
                                onClick={()=>(addExistingElement(schemeChilds.relationName, selectionPossibilities.id))}
                                >
                                  <td>
                                    {possibilitieId+1}
                                  </td>
                                  <td>
                                    {selectionPossibilities.id}
                                  </td>
                                  <td>
                                    {selectionPossibilities.name}
                                  </td>
                                  {selectionPossibilities.previewImage!=undefined&&
                                  <td>
                                    <img src={selectionPossibilities.previewImage}/>
                                  </td>}
                                    
                                </tr>
                              )
                            })}
                          </tbody>
                          </MDBTable>
                        </MDBRow>
                    </MDBCol>
                  }
      
      
      
                  {pagionation(
                    APIUrl,
                    token,
                    setModelElement,
                    modelElement.id, 
                    schemeChilds.relationName, 
                    modelElement.children[schemeChilds.relationName].pages, 
                    modelElement.children[schemeChilds.relationName].currentPage)}
                </MDBRow>
              </MDBAccordionItem>
            )

          }
          
        }
        )
      }
      </MDBAccordion>

    {modelElement.refererClasses.length>0&&
    <MDBRow className='border mb-3' style={{maxHeight:"400px", overflowX:"scroll"}}>
      <MDBRow className='my-3 ps-3'>
        <h5 >Referer Classes</h5>
        <hr style={{width:"80%", marginLeft:"1%"}}/>
      </MDBRow>
      <MDBRow className='mx-3'>
      {modelElement.refererClasses.map(referer=>{
        return(
        <MDBCard
          key={String(referer)}
          className='element-card'>
            <MDBCardHeader style={{height:"50%", fontWeight:"bold"}} className=''>
              {referer}
            </MDBCardHeader>
            <MDBCardFooter style={{height:"30%"}} className='d-flex align-items-center justify-content-center'>
              <MDBBtn 
              color='secondary' 
              className='border' 
              onClick={()=>{
                setAutoReferenced(false);
                navigate(`/model/${referer}`);
                
              }}>
                {languages.de.open}
              </MDBBtn>
            </MDBCardFooter>


          </MDBCard>
        )
      })      
      }
      
      </MDBRow>
    </MDBRow>
    }
    {(root!==undefined&&modelElement.id!==root.id&&autoReferenced)&&
      <MDBRow className='border my-3'>
        <MDBPopover size='lg' color='danger' dismiss btnChildren={languages.de.removeItem} placement='top'>
          <MDBPopoverHeader>{languages.de.warning}</MDBPopoverHeader>
          <MDBPopoverBody>
            <MDBRow className='mb-3'>
              {languages.de.inconsistencyWarning}
            </MDBRow>
            <MDBRow className='mb-3'>
              <MDBCol>
                <MDBBtn color='danger' onClick={()=>{deleteElementButton(modelElement.id)}}>
                  {languages.de.yes}
                </MDBBtn>
              </MDBCol>
              <MDBCol>
                <MDBBtn color='light'>
                  {languages.de.no}
                </MDBBtn>
              </MDBCol>

            </MDBRow>
          </MDBPopoverBody>
        </MDBPopover>
      </MDBRow>
    }
    
    
    
    </MDBContainer>

  )
}

export default ChildEditor