import { MDBRow } from 'mdb-react-ui-kit'
import React from 'react';
import './Impress.css';

function Impress() {
  return (
    <div>
        <MDBRow className='textBackground'>
            <h3>Impress</h3>
            <p>
                Adress:
                <br/>
                <span>
                    Beauco IT Consulting Andreas Bauer
                <br/>
                    Buchenstrasse 1
                <br/>
                    96120 Bischberg
                <br/>
                    Germany
                </span>
            </p>
            <p>
                E-Mail: 
                <br/>
                <a href="mailto:info@beauco-it.de">
                    info@beauco-it.de
                </a>
            </p>
            <p>
                Phone:
                <br/>
            <a href="tel:+49 1520 1587110">
                +49 1520 1587110
            </a>

                
            </p>

        </MDBRow>
    </div>
  )
}

export default Impress