import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import ChildEditor from './ChildEditor'
import HotspotEditor from './HotspotEditor'
import MapEditor from './MapEditor'
import MediaEditor from './MediaEditor'

function EditorSelector({
  APIUrl,
  token,
  modelElement,
  elementChildren,
  elementScheme,
  setModelElement,
  language,
  root,
  autoReferenced, 
  setAutoReferenced
}) {

  function selectEditor(editorName){
    switch(editorName){
      case 'GeometryMap':
        return(
          <MapEditor
          APIUrl={APIUrl}
          token={token}
          modelElement={modelElement}
          elementChildren={elementChildren}
          language={language}

          />
        )
      case 'Geometry360':
        return(
          <HotspotEditor
          APIUrl={APIUrl}
          token={token}
          modelElement={modelElement}
          elementChildren={elementChildren}
          elementScheme={elementScheme}
          setModelElement={setModelElement}
          language={language}

           />
        )
      case 'Media':
        return(
          <MediaEditor
          APIUrl={APIUrl}
          token={token}
          modelElement={modelElement}
          elementChildren={elementChildren}
          elementScheme={elementScheme}
          setModelElement={setModelElement}
          language={language}
            />
        )
      default:

        return(
          <ChildEditor
          APIUrl={APIUrl}
          token={token}
          modelElement={modelElement}
          elementChildren={elementChildren}
          elementScheme={elementScheme}
          setModelElement={setModelElement}
          language={language}
          root={root}
          setAutoReferenced={setAutoReferenced}
          autoReferenced={autoReferenced}
          />
        )
    }
  }



  return (
    <>
    {elementScheme !==undefined&&selectEditor(elementScheme.name)}
    </>
  )
}

export default EditorSelector