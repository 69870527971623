import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTabs, MDBTabsLink, MDBTabsItem, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';
import React, {useEffect, useState, useRef} from 'react';
import * as PANOLENS from 'panolens';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewProperty, alterPropertyWithoutName, getElement } from '../../../functions/APICalls';


function HotspotEditor({
  APIUrl,
  token,
  modelElement,
  elementChildren,
  elementScheme,
  setModelElement
}) {

  const {elementID} = useParams();
  const navigate = useNavigate();

  const [xValue, setXValue] = useState();
  const [yValue, setYValue] = useState();
  const [zValue, setZValue] = useState();

  const [hotspotImage, setHotspotImage] = useState();
  const [imageToggler, setImageToggler] = useState();
  const [imageAlert, setImageAlert] = useState();

  const viewer = useRef();
  const panorama = useRef();
  const infospot = useRef();
  const coordinates = useRef();

  useEffect(()=>{
    if(viewer.current)return;
    let name;
    viewer.current = new PANOLENS.Viewer({ 
      enableReticle: false, 
      output: 'console', 
      viewIndicator: true, 
      autoRotate: false, 
      autoRotateSpeed: 2, 
      autoRotateActivationDuration: 5000,
      autoHideInfospot: false,
      dwellTime: 1000,
      container: document.querySelector("#panoContainer")
    });

    infospot.current = new PANOLENS.Infospot(1, PANOLENS.DataImage.Info)


    coordinates.current = modelElement.properties.find(element=>element.name === "coordinates")
    if( coordinates.current === undefined){
      setXValue(parseInt(0));
      setYValue(parseInt(0));
      setZValue(parseInt(0));
      coordinates.current = [10,10,10]
    }else{
      coordinates.current = coordinates.current.value.split('|')

      setXValue(parseInt(coordinates.current[0]));
      setYValue(parseInt(coordinates.current[1]));
      setZValue(parseInt(coordinates.current[2]));
    }

    modelElement.refererClasses.map(hotspots=>{
      console.log(hotspots)
      getElement(APIUrl, token, hotspots)
      .then(returnedHotspots=>{
        console.log(returnedHotspots.refererClasses)
        setImageToggler(returnedHotspots.refererClasses);

      })
    })

    

   
    getElement(APIUrl,token, modelElement.refererClasses[0])
    .then(data=>{
      //console.log(data)
      getElement(APIUrl,token, data.refererClasses[0])
      .then(childData=>{
        console.log(childData)
        if(childData.children["360degreeImage"]!== undefined && childData.children["360degreeImage"].content !== undefined){
          getElement(APIUrl, token, childData.children["360degreeImage"].content[0].id)
          .then(image=>{
            console.log(image)
            image.properties.map(imageProp=>{
              if(imageProp.name==="fileName"){

                name=imageProp.value;
                panorama.current = new PANOLENS.ImagePanorama(`${APIUrl}/media/${name}?token=${token}`);
                
                viewer.current.add(panorama.current);

                infospot.current.position.set(coordinates.current[0],coordinates.current[1],coordinates.current[2])
                panorama.current.add(infospot.current);

                panorama.current.addEventListener("click",()=>{
                  //console.log("CLicked");
                  var intersects, point, panoramaWorldPosition, outputPosition;
                  var calculationValue=250;
                  intersects = viewer.current.raycaster.intersectObject(viewer.current.panorama, true);
                  //console.log(intersects)
                  if(intersects.length > 0){
                    point = intersects[0].point;
                    panoramaWorldPosition = viewer.current.panorama.getWorldPosition();
                    setXValue(-(point.x - panoramaWorldPosition.x).toFixed(2)/calculationValue);
                    setYValue((point.y - panoramaWorldPosition.y).toFixed(2)/calculationValue);
                    setZValue((point.z - panoramaWorldPosition.z).toFixed(2)/calculationValue)
                  }
            
                })

              }
            })
          })
          
        }




        // if(childData.children.hotspotImage !== undefined && childData.children.hotspotImage.content !== undefined){
        //   getElement(APIUrl,token,childData.children.hotspotImage.content[0].id)
        //   .then(hotspotImage=>{
        //     console.log(hotspotImage);
        //     if(hotspotImage.children["360degreeImage"]===undefined){
        //       setImageAlert(hotspotImage.id)
        //       return;
        //     }
        //     getElement(APIUrl,token,hotspotImage.children["360degreeImage"].content[0].id)
        //     .then(image=>{
        //       console.log(image)
        //       image.properties.map(imageProp=>{
        //         if(imageProp.name==="fileName"){

        //           name=imageProp.value;
        //           panorama.current = new PANOLENS.ImagePanorama(`${APIUrl}/media/${name}?token=${token}`);
                  
        //           viewer.current.add(panorama.current);

        //           infospot.current.position.set(coordinates.current[0],coordinates.current[1],coordinates.current[2])
        //           panorama.current.add(infospot.current);

        //           panorama.current.addEventListener("click",()=>{
        //             //console.log("CLicked");
        //             var intersects, point, panoramaWorldPosition, outputPosition;
        //             var calculationValue=250;
        //             intersects = viewer.current.raycaster.intersectObject(viewer.current.panorama, true);
        //             //console.log(intersects)
        //             if(intersects.length > 0){
        //               point = intersects[0].point;
        //               panoramaWorldPosition = viewer.current.panorama.getWorldPosition();
        //               setXValue(-(point.x - panoramaWorldPosition.x).toFixed(2)/calculationValue);
        //               setYValue((point.y - panoramaWorldPosition.y).toFixed(2)/calculationValue);
        //               setZValue((point.z - panoramaWorldPosition.z).toFixed(2)/calculationValue)
        //             }
              
        //           })
        //         }
        //       })
        //     })
        //   })
        // }else{
        //   //console.log("select image first")
        // }
      })
    })
  },[])

  useEffect(()=>{
    if(panorama.current===undefined)return;
    infospot.current.position.set(xValue,yValue,zValue);
  },[xValue, yValue, zValue])

  function alterPosition(){
    var propID = modelElement.properties.filter(element=>element.name==="coordinates")
    if(propID.length >0){
      propID = propID[0].id
      alterPropertyWithoutName(APIUrl, token, propID, `${xValue}%7C${yValue}%7C${zValue}`)
      .then(res=>{
        
      })
    }else{
      addNewProperty(APIUrl, token, "coordinates", modelElement.id, `${xValue}%7C${yValue}%7C${zValue}`)
      .then(res=>{
        document.getElementsByClassName('coordinates').forEach(element => {
          element.classList.add("is-validated")
        });
      })
    }

  }


 

  return (
    <>
      <MDBValidation  className='row g-3 mb-4'>

      <MDBValidationItem className='col-md-3'>
        <MDBInput 
        
        className='coordinates'
        label={"x-value"}
        type="number"
        value={xValue}
        onChange={(e)=>(setXValue(e.target.value))}/>
      </MDBValidationItem>
      <MDBValidationItem className='col-md-3'>
        <MDBInput 
        className='coordinates'
        label={"y-value"}
        type="number"
        value={yValue}
        onChange={(e)=>(setYValue(e.target.value))}/>
      </MDBValidationItem>
      <MDBValidationItem className='col-md-3'>
        <MDBInput 
        className='coordinates'
        label={"z-value"}
        type="number"
        value={zValue}
        onChange={(e)=>(setZValue(e.target.value))}/>
      </MDBValidationItem>
      <MDBCol md={3}>
        <MDBBtn
        color='secondary'
        onClick={()=>(alterPosition())}>
          <MDBIcon fas icon='fa-solid fa-check'/>
        </MDBBtn>
      </MDBCol>
      </MDBValidation>
    <MDBRow style={{width:"100%", height:"20vh"}}>
    <div id='panoContainer'style={{width:"100%", height:"400px"}}/>

      
      
      
    {(imageAlert!==undefined)&&
    <>
    <MDBRow className='d-flex justify-content-center align-items-center'>
    Please choose a 360° Image first
      <MDBBtn color='secondary' tag='a' onClick={()=>{navigate(`/model/${imageAlert}`)}}>
        <MDBIcon fas icon='fas-light fa-panorama '/>
      </MDBBtn>
    </MDBRow>
    </>
    }
    </MDBRow>

    </>
  )
}

export default HotspotEditor