import {useLocation, Navigate, Outlet} from 'react-router-dom';
function RequireAuth({
  user
}) {
    const location = useLocation();
  return (
    user
      ? <Outlet />
      : <Navigate to="/login" state={{from:location}}/>
  )
}

export default RequireAuth