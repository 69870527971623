import { MDBBtn, MDBCol, MDBCollapse, MDBContainer, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBInput, MDBInputGroup, MDBNavbar, MDBNavbarBrand, MDBNavbarItem, MDBNavbarNav, MDBNavbarToggler, MDBRow } from 'mdb-react-ui-kit'
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';

import '../Model.css';
import { findElementFromAll, findElementMultiple, getModelRoot } from '../../../functions/APICalls';

function SearchBar({
    APIUrl,
    token, 
    setSearchedElements,
    mandantSettings
}) {

    const navigate = useNavigate();

    const [showSearch, setShowSearch] = useState(false);
    const [searchVariable, setSearchVariable] = useState("All");
    const [searchValue, setSearchValue] = useState();
    const [searchError, setSearchError] = useState();

    const handleSearchSubmit = (e) =>{
        e.preventDefault();
        searchItem(searchValue, searchVariable)

    }


    const searchItem = (searchValueFromInput, searchVariableFromInput) => {
        if(searchValueFromInput==="" || searchValueFromInput===undefined){
            setSearchError("please insert search value")
        }else if(searchVariableFromInput==="All"){
            findElementFromAll(APIUrl, token, searchValueFromInput, 0)
            .then(data=>{
                setSearchedElements(data.elements)
                console.log(data)
                setSearchError(undefined)
                navigate(`/search/${searchVariableFromInput}/${searchValueFromInput}`);
                
            })
            
            
        }else{
            findElementMultiple(APIUrl, token, String(searchVariableFromInput).toLowerCase(), searchValueFromInput, 0)
            .then(data=>{
                if(data[String(searchVariableFromInput).toLowerCase()]!==undefined){
                    setSearchedElements(data)
                    setSearchError(undefined)
                    navigate(`/search/${searchVariableFromInput}/${searchValueFromInput}`);
                }else{
                    setSearchError("no Result found")
                    removeErrorMessage();

                }  
            })
            .catch(err=>{
                setSearchError("no Result found")
                removeErrorMessage();


            })
        }
        
    }

    const removeErrorMessage = () =>{
        setTimeout(()=>{
            setSearchError(undefined)
        },5000)
    }

    const removeSearch = () => {
        setSearchValue("")
        setSearchError(undefined)
    }

  return (
    <MDBNavbar sticky expand='sm' light bgColor={'light'} className='shadow-0'>
        <MDBNavbarBrand className='px-4 border-end' >
            <NavLink onClick={()=>{
                getModelRoot(APIUrl, token)
                .then(root=>navigate(`/model/${root.id}`))
            }}
            style={{color:mandantSettings!==undefined?mandantSettings.primaryColor:"black"}}
            >
                Start
            </NavLink>
        </MDBNavbarBrand>
        
        <MDBContainer fluid >
            <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle search bar'
            onClick={() => setShowSearch(!showSearch)}
            >
                <MDBIcon icon='fa-solid fa-magnifying-glass' fas />
            </MDBNavbarToggler>
            <MDBCollapse navbar show={showSearch}>
                <MDBNavbarNav>
                    <MDBNavbarItem className='mx-5'>
                        <MDBDropdown group className='shadow-0' >
                            <MDBDropdownToggle color='dark' tag='div' style={{width:"70px"}}>
                                {"Search by: "+searchVariable + " "}
                            </MDBDropdownToggle>
                            <MDBDropdownMenu className='dropdownSelectionMenue'> 
                             <MDBDropdownItem className='dropdownSelection border-bottom' childTag={'button'} onClick={(e)=>(setSearchVariable(e.target.innerHTML))}>
                                    All
                                </MDBDropdownItem>
                                <MDBDropdownItem className='dropdownSelection border-bottom' childTag={'button'} onClick={(e)=>(setSearchVariable(e.target.innerHTML))}>
                                    Name
                                </MDBDropdownItem>
                                <MDBDropdownItem className='dropdownSelection border-bottom' childTag={'button'} onClick={(e)=>(setSearchVariable(e.target.innerHTML))}>
                                    ID
                                </MDBDropdownItem>
                                <MDBDropdownItem className='dropdownSelection border-bottom' childTag={'button'} onClick={(e)=>(setSearchVariable(e.target.innerHTML))}>
                                    Type
                                </MDBDropdownItem>

                            </MDBDropdownMenu>
                            
                        </MDBDropdown>
                    </MDBNavbarItem>
                    <MDBNavbarItem className='mx-4'>

                    <form onSubmit={(e)=>handleSearchSubmit(e)}>
                        <MDBInputGroup
                        textAfter={
                            <>
                            <MDBBtn
                            className='mx-3' 
                            type='submit' 
                            tag={'a'} 
                            color='none' 
                            style={{ color: 'gray' }} 
                            onClick={()=>(searchItem(searchValue, searchVariable))}>
                                <MDBIcon fa icon='fa-light fa-magnifying-glass' />
                            </MDBBtn>
                            <div id="line" style={{height:"100%", width:"1",backgroundColor: '#909090' }}/>
                            <MDBBtn  
                            className='mx-3' 
                            tag={'a'} 
                            color='none' 
                            style={{ color: 'gray' }} 
                            onClick={()=>(removeSearch(searchValue, searchVariable))}>
                                <MDBIcon fa icon='fa-light fa-arrow-rotate-left' />
                            </MDBBtn>
                            </>

                        }>
                            <MDBInput 
                            type={'text'} 
                            size='sm'
                            value={searchValue}
                            onChange={(e)=>{setSearchValue(e.target.value)}}
                            placeholder="Search"
                            >
                        </MDBInput>
                        
                        </MDBInputGroup>
                        

                        

                            
                    </form>
                    </MDBNavbarItem>
                    {searchError!==undefined&&
                    <MDBNavbarItem className='mx-2' style={{color:"red"}} >
                        {searchError}
                    </MDBNavbarItem>
                    }

                </MDBNavbarNav>
            </MDBCollapse>
        
        </MDBContainer>
    </MDBNavbar>
  )
}

export default SearchBar